//
//  SDKSource.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTPluginPreciseCopyStrategy,
  DTPluginThemeOverrideStrategy,
} from "../../tools/design-tokens/utilities/SDKDTMapLoader"
import { ImportWarningType } from "../enums/SDKCloudWarningType"
import { SourceType } from "../enums/SDKSourceType"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

enum SourceRemoteType {
  figma = "Figma",
  tokenStudio = "TokenStudio",
  figmaVariablesPlugin = "FigmaVariablesPlugin",
  upload = "Upload",
}

export type SourceRemoteModel = {
  type: SourceRemoteType
  id: string
  fileName?: string
  brandId?: string
  themeId?: string
  plugin?: {
    fileId: string
    lastImportedAt?: string
  }
  cloud?: SourceRemoteModelCloud
  tokenStudio?: SourceRemoteModelTokensStudio
  figmaVariablesPlugin?: SourceRemoteModelFigmaVariablesPlugin
  scope: SourceScope
}

export type SourceScope = {
  tokens: boolean
  components: boolean
  assets: boolean
  documentationFrames: boolean
  isUnpublishedContentFallbackEnabled: boolean
}

export type SourceRemoteModelCloud = {
  fileId?: string
  autoImportMode: "Never" | "Hourly"
  fileThumbnailUrl?: string
  lastUpdatesCheckedAt?: string
  lastImportedVersion?: {
    id: string
    /** Date of file creation in Figma */
    created_at: string
    label?: string
    description: string
  }
  /** This indicates last import try attempt, either failed or succeed  */
  lastImportedAt?: string
  /** This is last successful import results, so if last import failed this won't change */
  lastImportResult?: {
    sourceId: string
    brandId: string
    tokensCreated: number
    tokensUpdated: number
    tokensDeleted: number
    componentsCreated: number
    componentsUpdated: number
    componentsDeleted: number
    componentAssetsCreated: number
    componentAssetsUpdated: number
    componentAssetsDeleted: number
    isFailed: boolean
    versionId: string
    fileSize?: number
    warnings?: Array<SourceImportResultWarning>
    isFramesFailed: boolean
    assetsInFile?: {
      frames: number
      components: number
    }
    invalidReferencesCount?: number
  }
  ownerId: string
  ownerUserName: string
  state:
  | "Active"
  | "MissingIntegration"
  | "MissingFileAccess"
  | "MissingFileOwner"
  stats: {
    tokens: number
    components: number
    assets: number
    frames: number
  }
}

export type SourceImportResultWarning = {
  warningType: ImportWarningType
  componentId: string
  componentName: string
  styleId: string
  styleName: string
  unsupportedStyleValueType: string
}

// note:
// there can be multiple results;
//  - Base source result: there is no themeId, base tokens
//  - Theme source results: there is themeId, theme tokens
export type SourceRemoteModelTokensStudio = {
  connectionName: string
  lastImportedAt?: string
  settings?: {
    dryRun?: boolean
    verbose?: boolean
    preciseCopy?: boolean | DTPluginPreciseCopyStrategy
    themeOverridesStrategy?: DTPluginThemeOverrideStrategy
  }
  lastImportedResults?: Array<{
    mapping: {
      tokensTheme?: string
      tokenSets?: string[]
      supernovaBrand?: string
      supernovaTheme?: string
    }
    tokensCreated: number
    tokensUpdated: number
    tokensDeleted: number
    isFailed: boolean
    error?: string
    themeId?: string
  }>
}

export type SourceRemoteModelFigmaVariablesPlugin = {
  fileId: string
  lastImportedAt?: string
  lastImportMetadata?: Record<string, any>
  isTokenTypeSplitEnabled: boolean
}

export type SourceModelTokenStudio = Omit<
  SourceRemoteModelTokensStudio,
  "lastUpdatesCheckedAt" | "lastImportedAt"
> & {
  lastUpdatesCheckedAt?: Date
  lastImportedAt?: Date
}

export type SourceModelCloud = Omit<
  SourceRemoteModelCloud,
  "lastUpdatesCheckedAt" | "lastImportedAt"
> & {
  lastUpdatesCheckedAt?: Date
  lastImportedAt?: Date
}

export type SourceModelFigmaVariablesPlugin = Omit<
  SourceRemoteModelFigmaVariablesPlugin,
  "lastImportedAt"
> & {
  lastImportedAt?: Date
}

export type SourceTransportModel = Pick<
  Source,
  "type" | "id" | "fileName" | "brandId" | "themeId" | "scope"
> & {
  cloud?: SourceRemoteModelCloud | null
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class Source {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: SourceType

  id: string

  fileName: string | null

  brandId: string | null

  themeId: string | null

  cloud: SourceModelCloud | null

  tokenStudio?: SourceModelTokenStudio | null

  figmaVariablesPlugin?: SourceModelFigmaVariablesPlugin | null

  scope: SourceScope

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Getter & Setters

  get lastImportedAt(): Date | null {
    if (this.cloud?.lastImportedAt) {
      return this.cloud.lastImportedAt
    }

    if (this.tokenStudio?.lastImportedAt) {
      return this.tokenStudio.lastImportedAt
    }

    if (this.figmaVariablesPlugin?.lastImportedAt) {
      return this.figmaVariablesPlugin.lastImportedAt
    }

    return null
  }

  get hasError(): boolean {
    const lastImportResult = this.cloud?.lastImportResult
    const hasImportError = lastImportResult?.isFailed || !this.isCloudAvailable

    if (Object.values(this.scope).every((value) => !value) && !hasImportError) {
      // we don't show error for scope-less source
      return false
    }

    // If the import failed completely, it counts as an error
    return !lastImportResult || hasImportError
  }

  get hasWarning(): boolean {
    const lastImportResult = this.cloud?.lastImportResult

    if (!lastImportResult) return false
    // Any error warnings count as an error
    if (lastImportResult.warnings?.length) return true
    return false
  }

  get isCloudAvailable(): boolean {
    if (this.cloud) {
      return this.cloud.state === "Active"
    }

    return false
  }

  get isTokensStudioAvailable(): boolean {
    return !!this.tokenStudio
  }

  get tokensStudioHasError(): boolean {
    const lastImportedResults = this.tokenStudio?.lastImportedResults

    // Tokens studio source import results can not be null
    if (!lastImportedResults) return true
    return lastImportedResults.some((result) => result.isFailed)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: SourceRemoteModel) {
    this.type = sourceTypeFromRemote(model.type)
    this.id = model.id
    this.fileName = model.fileName ?? null
    this.brandId = model.brandId ?? null
    this.themeId = model.themeId ?? null
    this.fileName = model.fileName ?? null

    if (model.cloud) {
      this.cloud = {
        fileId: model.cloud?.fileId ?? undefined,
        autoImportMode: model.cloud?.autoImportMode ?? null,
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        fileThumbnailUrl: model.cloud?.fileThumbnailUrl ?? null,
        ownerId: model.cloud?.ownerId ?? null,
        ownerUserName: model.cloud?.ownerUserName ?? null,
        state: model.cloud?.state ?? null,
        stats: model.cloud?.stats ?? null,
        // @ts-expect-error TS(2322): Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
        lastUpdatesCheckedAt: model.cloud?.lastUpdatesCheckedAt
          ? new Date(model.cloud.lastUpdatesCheckedAt)
          : null,
        // @ts-expect-error TS(2322): Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
        lastImportedAt: model.cloud?.lastImportedAt
          ? new Date(model.cloud.lastImportedAt)
          : null,
        // @ts-expect-error TS(2322): Type '{ id: string; created_at: string; label: str... Remove this comment to see the full error message
        lastImportedVersion: model.cloud?.lastImportedVersion
          ? {
            id: model.cloud.lastImportedVersion.id,
            created_at: model.cloud.lastImportedVersion.created_at,
            label: model.cloud.lastImportedVersion.label ?? null,
            description: model.cloud.lastImportedVersion.description,
          }
          : null,
        // @ts-expect-error TS(2322): Type '{ sourceId: string; brandId: string; tokensC... Remove this comment to see the full error message
        lastImportResult: model.cloud?.lastImportResult
          ? {
            sourceId: model.cloud.lastImportResult.sourceId,
            brandId: model.cloud.lastImportResult.brandId,
            tokensCreated: model.cloud.lastImportResult.tokensCreated,
            tokensUpdated: model.cloud.lastImportResult.tokensUpdated,
            tokensDeleted: model.cloud.lastImportResult.tokensDeleted,
            componentsCreated: model.cloud.lastImportResult.componentsCreated,
            componentsUpdated: model.cloud.lastImportResult.componentsUpdated,
            componentsDeleted: model.cloud.lastImportResult.componentsDeleted,
            componentAssetsCreated:
              model.cloud.lastImportResult.componentAssetsCreated,
            componentAssetsUpdated:
              model.cloud.lastImportResult.componentAssetsUpdated,
            componentAssetsDeleted:
              model.cloud.lastImportResult.componentAssetsDeleted,
            isFailed: model.cloud.lastImportResult.isFailed,
            versionId: model.cloud.lastImportResult.versionId,
            fileSize: model.cloud.lastImportResult.fileSize ?? null,
            warnings: model.cloud.lastImportResult.warnings ?? [],
            isFramesFailed: model.cloud.lastImportResult.isFailed,
            assetsInFile: model.cloud.lastImportResult.assetsInFile
              ? {
                frames: model.cloud.lastImportResult.assetsInFile.frames,
                components:
                  model.cloud.lastImportResult.assetsInFile.components,
              }
              : null,
            invalidReferencesCount:
              model.cloud.lastImportResult.invalidReferencesCount ?? null,
          }
          : null,
      }
    } else {
      this.cloud = null
    }

    if (model.tokenStudio) {
      this.tokenStudio = {
        connectionName: model.tokenStudio.connectionName,
        lastImportedAt: model.tokenStudio.lastImportedAt
          ? new Date(model.tokenStudio.lastImportedAt)
          : undefined,
        settings: model.tokenStudio.settings,
        lastImportedResults: model.tokenStudio.lastImportedResults,
      }
    }

    if (model.figmaVariablesPlugin) {
      this.figmaVariablesPlugin = {
        fileId: model.figmaVariablesPlugin.fileId,
        lastImportedAt: model.figmaVariablesPlugin.lastImportedAt
          ? new Date(model.figmaVariablesPlugin.lastImportedAt)
          : undefined,
        lastImportMetadata: model.figmaVariablesPlugin.lastImportMetadata,
        isTokenTypeSplitEnabled: model.figmaVariablesPlugin.isTokenTypeSplitEnabled,
      }
    }

    this.scope = model.scope
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): SourceRemoteModel {
    return {
      type: sourceRemoteTypeFromLocal(this.type),
      id: this.id,
      fileName: this.fileName ?? undefined,
      brandId: this.brandId ?? undefined,
      themeId: this.themeId ?? undefined,
      cloud: this.cloud
        ? {
          fileId: this.cloud.fileId,
          autoImportMode: this.cloud.autoImportMode,
          fileThumbnailUrl: this.cloud.fileThumbnailUrl,
          lastUpdatesCheckedAt: this.cloud.lastUpdatesCheckedAt
            ? this.cloud.lastUpdatesCheckedAt.toISOString()
            : undefined,
          lastImportedAt: this.cloud.lastImportedAt
            ? this.cloud.lastImportedAt.toISOString()
            : undefined,
          lastImportedVersion: this.cloud.lastImportedVersion,
          lastImportResult: this.cloud.lastImportResult,
          ownerId: this.cloud.ownerId,
          ownerUserName: this.cloud.ownerUserName,
          state: this.cloud.state,
          stats: this.cloud.stats,
        }
        : undefined,
      scope: this.scope,
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): SourceTransportModel {
    return {
      type: this.type,
      id: this.id,
      fileName: this.fileName,
      cloud: this.cloud
        ? {
          fileId: this.cloud.fileId,
          autoImportMode: this.cloud.autoImportMode,
          fileThumbnailUrl: this.cloud.fileThumbnailUrl,
          lastUpdatesCheckedAt: this.cloud.lastUpdatesCheckedAt
            ? this.cloud.lastUpdatesCheckedAt.toISOString()
            : undefined,
          lastImportedAt: this.cloud.lastImportedAt
            ? this.cloud.lastImportedAt.toISOString()
            : undefined,
          lastImportedVersion: this.cloud.lastImportedVersion,
          lastImportResult: this.cloud.lastImportResult,
          ownerId: this.cloud.ownerId,
          ownerUserName: this.cloud.ownerUserName,
          state: this.cloud.state,
          stats: this.cloud.stats,
        }
        : null,
      brandId: this.brandId,
      themeId: this.themeId,
      scope: this.scope,
    }
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: SourceTransportModel): SourceRemoteModel {
    return {
      type: sourceRemoteTypeFromLocal(model.type),
      id: model.id,
      fileName: model.fileName ?? undefined,
      brandId: model.brandId ?? undefined,
      themeId: model.themeId ?? undefined,
      scope: model.scope,
      // @ts-expect-error TS(2322): Type '{ lastUpdatesCheckedAt: string | undefined; ... Remove this comment to see the full error message
      cloud: {
        ...model.cloud,
        // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
        lastUpdatesCheckedAt: model.cloud.lastUpdatesCheckedAt,
        // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
        lastImportedAt: model.cloud.lastImportedAt,
      },
    }
  }
}

function sourceTypeFromRemote(remoteType: SourceRemoteType): SourceType {
  switch (remoteType) {
    case SourceRemoteType.figma:
      return SourceType.figma
    case SourceRemoteType.tokenStudio:
      return SourceType.tokenStudio
    case SourceRemoteType.figmaVariablesPlugin:
    case SourceRemoteType.upload:
      return SourceType.figmaVariablesPlugin

    default:
      return SourceType.figma
  }
}

function sourceRemoteTypeFromLocal(remoteType: SourceType): SourceRemoteType {
  switch (remoteType) {
    case SourceType.figma:
      return SourceRemoteType.figma
    case SourceType.tokenStudio:
      return SourceRemoteType.tokenStudio
    case SourceType.figmaVariablesPlugin:
      return SourceRemoteType.figmaVariablesPlugin

    default:
      return SourceRemoteType.figma
  }
}
