/* eslint-disable max-lines */
//
//  AreaDocumentation.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTOCreateDocumentationGroupInput,
  DTOCreateDocumentationPageInputV2,
  DTOUpdateDocumentationGroupInput,
  DTOUpdateDocumentationPageInputV2,
  DTODuplicateDocumentationGroupInput,
  DTODuplicateDocumentationPageInputV2,
  DTODeleteDocumentationGroupInput,
  DTOMoveDocumentationPageInputV2,
  DTOMoveDocumentationGroupInput,
  DTOCreateDocumentationTabInput,
  DTODocumentationPageAnchor,
  DTOGetDocumentationPageAnchorsResponse,
  DTODesignElementsDataDiffResponse,
  DTOPublishDocumentationRequest,
  DTOElementActionInput,
  DTOElementActionOutput,
} from "@supernova-studio/client"

import {
  DocumentationLegacy,
  DocumentationLegacyModel,
} from "../../model/base/SDKDocumentationLegacy"
import { DocsBlockDefinition } from "../../model/docs/blocks/definition/SDKDocsBlockDefinition"
import { Assets } from "../../model/docs/blocks/definition/actual/assets/SDKDocsBlockActualAssets"
import { Blockquote } from "../../model/docs/blocks/definition/actual/blockquote/SDKDocsBlockActualBlockquote"
import { Callout } from "../../model/docs/blocks/definition/actual/callout/SDKDocsBlockActualCallout"
import { CodeReact } from "../../model/docs/blocks/definition/actual/code-react/SDKDocsBlockActualCodeReact"
import { Code } from "../../model/docs/blocks/definition/actual/code/SDKDocsBlockActualCode"
import { AccessibilityGrid } from "../../model/docs/blocks/definition/actual/color-accessibility-grid/SDKDocsBlockActualAccessibilityGrid"
import { ColorRamps } from "../../model/docs/blocks/definition/actual/color-ramps/SDKDocsBlockActualColorRamps"
import { ComponentChecklistAll } from "../../model/docs/blocks/definition/actual/component-checklist-all/SDKDocsBlockActualComponentChecklistAll"
import { ComponentChecklist } from "../../model/docs/blocks/definition/actual/component-checklist/SDKDocsBlockActualComponentChecklist"
import { ComponentHealth } from "../../model/docs/blocks/definition/actual/component-health/SDKDocsBlockActualComponentHealth"
import { DesignTokens } from "../../model/docs/blocks/definition/actual/design-tokens/SDKDocsBlockActualDesignTokens"
import { Divider } from "../../model/docs/blocks/definition/actual/divider/SDKDocsBlockActualDivider"
import { DoDontGuidelines } from "../../model/docs/blocks/definition/actual/do-dont-guidelines/SDKDocsBlockActualDoDontGuidelines"
import { EmbedFigma } from "../../model/docs/blocks/definition/actual/embed-figma/SDKDocsBlockActualEmbedFigma"
import { EmbedLottie } from "../../model/docs/blocks/definition/actual/embed-lottie/SDKDocsBlockActualEmbedLottie"
import { EmbedYoutube } from "../../model/docs/blocks/definition/actual/embed-youtube/SDKDocsBlockActualEmbedYoutube"
import { Embed } from "../../model/docs/blocks/definition/actual/embed/SDKDocsBlockActualEmbed"
import { FigmaComponentPropsTable } from "../../model/docs/blocks/definition/actual/figma-component-props-table/SDKDocsBlockActualFigmaComponentPropsTable"
import { FigmaComponents } from "../../model/docs/blocks/definition/actual/figma-component/SDKDocsBlockActualFigmaComponent"
import { FigmaFrames } from "../../model/docs/blocks/definition/actual/figma-frames/SDKDocsBlockActualFigmaFrames"
import { Image } from "../../model/docs/blocks/definition/actual/image/SDKDocsBlockActualImage"
import { Markdown } from "../../model/docs/blocks/definition/actual/markdown/SDKDocsBlockActualMarkdown"
import { OrderedList } from "../../model/docs/blocks/definition/actual/ordered-list/SDKDocsBlockActualOrderedList"
import { ReleaseNotes } from "../../model/docs/blocks/definition/actual/release-notes/SDKDocsBlockActualReleaseNotes"
import { RichText } from "../../model/docs/blocks/definition/actual/rich-text/SDKDocsBlockActualRichText"
import { ShortcutLinks } from "../../model/docs/blocks/definition/actual/shortcut-links/SDKDocsBlockActualShortcutLinks"
import { Storybook } from "../../model/docs/blocks/definition/actual/storybook/SDKDocsBlockActualStorybook"
import { Table } from "../../model/docs/blocks/definition/actual/table/SDKDocsBlockActualTable"
import { Title1 } from "../../model/docs/blocks/definition/actual/title1/SDKDocsBlockActualTitle1"
import { Title2 } from "../../model/docs/blocks/definition/actual/title2/SDKDocsBlockActualTitle2"
import { Title3 } from "../../model/docs/blocks/definition/actual/title3/SDKDocsBlockActualTitle3"
import { Title4 } from "../../model/docs/blocks/definition/actual/title4/SDKDocsBlockActualTitle4"
import { Title5 } from "../../model/docs/blocks/definition/actual/title5/SDKDocsBlockActualTitle5"
import { UnorderedList } from "../../model/docs/blocks/definition/actual/unordered-list/SDKDocsBlockActualUnorderedList"
import { DocsBlock } from "../../model/docs/blocks/usage/SDKDocsBlock"
import { DocsEntity } from "../../model/docs/entities/SDKDocsEntity"
import { DocsEntityGroupModel } from "../../model/docs/entities/SDKDocsEntityGroup"
import { DocsEntityPageModel } from "../../model/docs/entities/SDKDocsEntityPage"
import { DocsSection } from "../../model/docs/sections/SDKDocsSection"
import {
  DocumentationLegacyGroup,
  DocumentationLegacyGroupModel,
} from "../../model/documentation/SDKDocumentationLegacyGroup"
import { DocumentationLegacyPage } from "../../model/documentation/SDKDocumentationLegacyPage"
import { DocsPublishStatus } from "../../model/enums/SDKDocsPublishStatus"
import { DocumentationEnvironment } from "../../model/enums/SDKDocumentationEnvironment"
import { DocumentationLegacyItemType } from "../../model/enums/SDKDocumentationLegacyItemType"
import {
  ExportBuild,
  ExportBuildStatus,
} from "../../model/exporters/exporter_builds/SDKExportBuild"
import { DataCore } from "../data/SDKDataCore"

import {
  RemoteVersionIdentifier,
  RemoteWorkspaceVersionIdentifier,
} from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaDocumentation {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches object containing the main information about the docs. This does not contain structure or content of the documentation
   * @param from - Remote version to fetch from
   * @returns Documentation object
   */
  async getDocumentation(
    from: RemoteWorkspaceVersionIdentifier
  ): Promise<DocumentationLegacy> {
    return this.dataCore.documentation(
      from.workspaceId,
      from.designSystemId,
      from.versionId
    )
  }

  async getDocumentationStructure(
    from: RemoteWorkspaceVersionIdentifier
  ): Promise<(DocsEntityPageModel | DocsEntityGroupModel)[]> {
    return this.dataCore.getDocumentationStructure(
      from.designSystemId,
      from.versionId
    )
  }

  /**
   * Fetches the differences in elements, such as design tokens and assets, that have occurred since the documentation was published.
   * @param from Remote version to fetch from
   * @returns Documentation elements diff
   */
  async getDocumentationElementsDiff(
    from: RemoteWorkspaceVersionIdentifier,
    environment: DocumentationEnvironment
  ): Promise<DTODesignElementsDataDiffResponse> {
    return this.dataCore.getDocumentationElementsDiff(
      from.designSystemId,
      from.versionId,
      environment
    )
  }

  /** Fetches url string where to find documentation
   * @param from - Remote version to fetch from
   * @returns documentation url string
   */
  async getDocumentationUrl(from: RemoteVersionIdentifier): Promise<string> {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    return this.dataCore.documentationDomain(
      from.designSystemId,
      from.versionId
    )
  }

  /** Retrieve information for an ongoing publishing build if any exists. */
  async isPublishing(
    to: RemoteWorkspaceVersionIdentifier,
    environment: DocumentationEnvironment
  ): Promise<
    | { status: DocsPublishStatus.inProgress; job: ExportBuild }
    | { status: DocsPublishStatus.idle; job: null }
  > {
    const jobs = await this.dataCore.documentationBuilds(
      to.workspaceId,
      to.versionId,
      environment,
      undefined,
      undefined,
      1
    )

    const job = jobs[0]

    if (job && job.status === ExportBuildStatus.InProgress) {
      return {
        status: DocsPublishStatus.inProgress,
        job,
      }
    }

    return {
      status: DocsPublishStatus.idle,
      job: null,
    }
  }

  /** Fetches a documentation build.
   * @param workspaceId - Identifier of the workspace associated with the build.
   * @param buildId - Identifier of the build.
   */
  async getDocumentationBuild(
    workspaceId: string,
    buildId: string
  ): Promise<ExportBuild> {
    return this.dataCore.exportBuild(workspaceId, buildId)
  }

  /** Fetches documentation builds filtered according to the params.
   * @param workspaceId - Identifier of the workspace associated with the builds.
   * @param versionId - Identifier of the design system version associated with the builds.
   * @param environment - Environment of the builds.
   * @param status - Status of the builds.
   * @param createdByUserId - Identifier of the user who created the builds.
   * @param limit - Number of builds to return.
   */
  async getDocumentationBuilds(
    workspaceId: string,
    versionId: string,
    environment: DocumentationEnvironment,
    status?: ExportBuildStatus,
    createdByUserId?: string,
    limit = 1
  ): Promise<Array<ExportBuild>> {
    return this.dataCore.documentationBuilds(
      workspaceId,
      versionId,
      environment,
      status,
      createdByUserId,
      limit
    )
  }

  /** Updates documentation structure
   * @param from - Remote version to fetch from
   * @param model - documentation legacy model
   * @returns nothing
   */
  async updateDocumentationStructure(
    from: RemoteVersionIdentifier,
    model: DocumentationLegacyModel
  ): Promise<void> {
    await this.dataCore.updateDocumentationStructure(
      from.designSystemId,
      from.versionId,
      model
    )
  }

  /** Creates a documentation page within documentation group
   * @param from - Remote version to fetch from
   * @param payload - The update model
   * @returns Newly created documentation page
   */
  async createDocumentationPage(
    from: RemoteWorkspaceVersionIdentifier,
    payload: Omit<DTOCreateDocumentationPageInputV2, "persistentId">
  ): Promise<string> {
    return this.dataCore.createDocumentationPage(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** General element action */
  async elementAction(
    from: RemoteWorkspaceVersionIdentifier,
    payload: DTOElementActionInput
  ): Promise<DTOElementActionOutput> {
    return this.dataCore.elementAction(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Creates a documentation group within documentation group
   * @param from - Remote version to fetch from
   * @param parentItemPersistentId - Peristent ID of the parent group
   * @param data - The update model
   * @returns Newly created documentation group
   */
  async createDocumentationGroup(
    from: RemoteWorkspaceVersionIdentifier,
    payload: Omit<DTOCreateDocumentationGroupInput, "persistentId">
  ): Promise<string> {
    return this.dataCore.createDocumentationGroup(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Creates a documentation tab group within documentation group
   * @param from - Remote version to fetch from
   * @param data - The update model
   * @returns Newly created documentation group
   */
  async createDocumentationTab(
    from: RemoteWorkspaceVersionIdentifier,
    payload: Omit<DTOCreateDocumentationTabInput, "persistentId">
  ): Promise<string> {
    return this.dataCore.createDocumentationTab(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Updates a documentation group within documentation group
   * @param from - Remote version to fetch from
   * @param group - Group to update
   * @param updateModel - Group update model with optional fields
   * @returns Newly created documentation group
   */
  async updateDocumentationGroup(
    from: RemoteWorkspaceVersionIdentifier,
    payload: DTOUpdateDocumentationGroupInput
  ) {
    return this.dataCore.updateDocumentationGroup(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Moves a documentation page
   * @param from - Remote version to fetch from
   * @param payload - The update model
   * @returns nothing
   */
  async moveDocumentationPage(
    from: RemoteWorkspaceVersionIdentifier,
    payload: DTOMoveDocumentationPageInputV2
  ) {
    return this.dataCore.moveDocumentationPage(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Moves a documentation group
   * @param from - Remote version to fetch from
   * @param payload - The update model
   * @returns nothing
   */
  async moveDocumentationGroup(
    from: RemoteWorkspaceVersionIdentifier,
    payload: DTOMoveDocumentationGroupInput
  ) {
    return this.dataCore.moveDocumentationGroup(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Deletes a documentation group
   * @param from - Remote version to fetch from
   * @param group - Group to delete
   * @returns nothing
   */
  async deleteDocumentationGroup(
    from: RemoteWorkspaceVersionIdentifier,
    payload: DTODeleteDocumentationGroupInput
  ) {
    return this.dataCore.deleteDocumentationGroup(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Deletes a documentation group and its tree
   * @param from - Remote version to fetch from
   * @param group - Group to delete
   * @returns nothing
   */
  async deleteDocumentationGroupAndTree(
    from: RemoteWorkspaceVersionIdentifier,
    id: string
  ): Promise<void> {
    return this.dataCore.deleteDocumentationGroupAndTree(
      from.designSystemId,
      from.versionId,
      id
    )
  }

  /** Deletes a documentation page
   * @param from - Remote version to fetch from
   * @param page - Page to delete
   * @returns nothing
   */
  async deleteDocumentationPage(
    from: RemoteWorkspaceVersionIdentifier,
    id: string
  ): Promise<void> {
    return this.dataCore.deleteDocumentationPage(
      from.designSystemId,
      from.versionId,
      {
        id,
      }
    )
  }

  /** Restores a documentation page
   * @param from - Remote version to fetch from
   * @param persistentId - persistentId of a page to restore
   * @param snapshotId - Identifier of a snapshot of a page we want to restore to.
   * @returns nothing
   */
  async restoreDocumentationPage(
    from: RemoteWorkspaceVersionIdentifier,
    persistentId: string,
    snapshotId?: string
  ): Promise<void> {
    return this.dataCore.restoreDocumentationPage(
      from.designSystemId,
      from.versionId,
      {
        persistentId,
        snapshotId,
      }
    )
  }

  /** Restores a documentation group
   * @param from - Remote version to fetch from
   * @param persistentId - persistentId of a group to restore
   * @param snapshotId - Identifier of a snapshot of a group we want to restore to.
   * @returns nothing
   */
  async restoreDocumentationGroup(
    from: RemoteWorkspaceVersionIdentifier,
    persistentId: string,
    snapshotId?: string
  ): Promise<void> {
    return this.dataCore.restoreDocumentationGroup(
      from.designSystemId,
      from.versionId,
      {
        persistentId,
        snapshotId,
      }
    )
  }

  /** Deletes a documentation tab group
   * @param from - Remote version to fetch from
   * @param page - Page to delete
   * @returns nothing
   */
  async deleteDocumentationTabGroup(
    from: RemoteWorkspaceVersionIdentifier,
    id: string
  ): Promise<void> {
    return this.dataCore.deleteDocumentationTabGroup(
      from.designSystemId,
      from.versionId,
      {
        id,
      }
    )
  }

  /** Updates a documentation tab or page
   * @param from - Remote version to fetch from
   * @param page - page to update
   * @param updateModel - data to update page with
   * @returns page
   */
  async updateDocumentationPageOrTab(
    from: RemoteWorkspaceVersionIdentifier,
    payload: DTOUpdateDocumentationPageInputV2
  ) {
    return this.dataCore.updateDocumentationPageOrTab(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Duplicates a documentation tab or page
   * @param from - Remote version to fetch from
   * @param idInVersion - id of page or tab to be duplicated
   * @param newPersistentId - suggested persistent id of a new page or tab
   * @returns Promise<DocumentationLegacyPage>
   */
  async duplicateDocumentationPageOrTab(
    from: RemoteWorkspaceVersionIdentifier,
    payload: Omit<DTODuplicateDocumentationPageInputV2, "persistentId">
  ) {
    return this.dataCore.duplicateDocumentationPageOrTab(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Duplicates a documentation group or tabbed page
   * @param from - Remote version to fetch from
   * @param idInVersion - id of page or tab to be duplicated
   * @param newPersistentId - suggested persistent id of a new group or tabbed page
   * @returns duplicated group persistent id
   */
  async duplicateDocumentationGroupOrTabbedPage(
    from: RemoteWorkspaceVersionIdentifier,
    payload: Omit<DTODuplicateDocumentationGroupInput, "persistentId">
  ): Promise<string> {
    return this.dataCore.duplicateDocumentationGroupOrTabbedPage(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Ungroups a documentation group
   * @param from - Remote version to fetch from
   * @param idInVersion - id of a group
   * @returns nothing
   */
  async ungroupDocumentationGroup(
    from: RemoteWorkspaceVersionIdentifier,
    payload: DTODeleteDocumentationGroupInput
  ) {
    return this.dataCore.ungroupDocumentationGroup(
      from.designSystemId,
      from.versionId,
      payload
    )
  }

  /** Fetches object containing the structure of the docs - documentation pages and groups with metadata but without the page payloads
   * Not implemented for now
   * @param from - Remote version to fetch from
   * @returnsDocumentationLegacy object
   */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  async getDocumentationStructureFromRoot(
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from: RemoteWorkspaceVersionIdentifier
  ): Promise<DocumentationLegacyGroup> {
    throw new Error("Not implemented")
  }

  /** Fetches entire documentation documentation structure, including all pages and groups and their payloads
   * @param from - Remote version to fetch from
   * @returns All documentation items
   */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  async getDocumentationFromRoot(
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from: RemoteWorkspaceVersionIdentifier
  ): Promise<DocumentationLegacyGroup> {
    throw new Error("Not implemented")
  }

  /** Get ID of the multiplayer room for a given documentation page
   * @param from - Remote version to fetch from
   * @param pageId - ID of the documentation page
   * @returns Room ID that can be immediately used to join the multiplayer room
   */
  async getDocumentationPageRoomId(
    from: RemoteWorkspaceVersionIdentifier,
    pageId: string
  ): Promise<string> {
    return this.dataCore.getDocumentationPageRoomId(
      from.designSystemId,
      from.versionId,
      pageId
    )
  }

  /** Get id of the room for a specific design system and version. This room contains doc structure
   * @param from - Remote version to fetch from
   * @returns Room ID that can be immediately used to join the multiplayer room
   */
  async getDocumentationVersionRoomId(
    from: RemoteWorkspaceVersionIdentifier
  ): Promise<string> {
    return this.dataCore.getDocumentationVersionRoomId(
      from.designSystemId,
      from.versionId
    )
  }

  /** Get id of the room for a specific workspace. This rooms contains comments.
   * @param from - Remote version to fetch from
   * @returns Room ID that can be immediately used to join the multiplayer room
   */
  async getDocumentationWorkspaceRoomId(
    from: Pick<RemoteWorkspaceVersionIdentifier, "workspaceId">
  ): Promise<string> {
    return this.dataCore.getDocumentationWorkspaceRoomId(from.workspaceId)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Publish documentation. This queues a build on Supernova's server that will be processed by the asynchronous CI/CD pipeline.
   * You can request status of the build with associated `isPublishing` method.
   * @param to - Remote version where the build should be created
   * @param environment - Environment to publish to
   * @param isForcing - If true, a new build will be created regardless if an in-progress build already exists.
   * */
  async publish(
    to: RemoteWorkspaceVersionIdentifier,
    environment: DocumentationEnvironment,
    isForcing = false
  ): Promise<ExportBuild> {
    if (isForcing) {
      // Create a new build regardless of the current state.
      return this.dataCore.documentationPublish(
        to.workspaceId,
        to.designSystemId,
        to.versionId,
        environment
      )
    }

    // Check if doc is being published by downloading the latest documentation job. If so, prevent publishing
    const lastJobData = await this.isPublishing(to, environment)

    if (lastJobData.status === DocsPublishStatus.idle) {
      return this.dataCore.documentationPublish(
        to.workspaceId,
        to.designSystemId,
        to.versionId,
        environment
      )
    }

    return lastJobData.job
  }

  /** Publish documentation drafts. This queues a build on Supernova's server that will be processed by the asynchronous CI/CD pipeline.
   * You can request status of the build with associated `isPublishing` method. New endpoint supports publishing drafts.
   * @param to - Remote version where the build should be created
   * @param environment - Environment to publish to
   * @param changes - Specific page/group ids that needs to be published
   * @param isForcing - If true, a new build will be created regardless if an in-progress build already exists.
   * */
  async publishDrafts(
    to: RemoteWorkspaceVersionIdentifier,
    environment: DocumentationEnvironment,
    changes: DTOPublishDocumentationRequest["changes"],
    isForcing = false
  ) {
    if (isForcing) {
      // Create a new build regardless of the current state.
      return this.dataCore.documentationPublishDrafts(
        to.designSystemId,
        to.versionId,
        environment,
        changes
      )
    }

    // Check if doc is being published by downloading the latest documentation job. If so, prevent publishing
    const lastJobData = await this.isPublishing(to, environment)

    if (lastJobData.status === DocsPublishStatus.idle) {
      return this.dataCore.documentationPublishDrafts(
        to.designSystemId,
        to.versionId,
        environment,
        changes
      )
    }

    return lastJobData.job
  }

  async getDocumentationPageAnchors(
    from: RemoteWorkspaceVersionIdentifier,
    pageId: string
  ): Promise<Array<DTODocumentationPageAnchor>> {
    return this.dataCore.getDocumentationPageAnchors(
      from.designSystemId,
      from.versionId,
      pageId
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Compute

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Mock data

  getDocumentationMockedStructure(): Array<DocsEntity> {
    // TODO Mocked documentation structure
    return []
  }

  getDocumentationMockedPageContent(
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pageId: string
  ): Array<DocsBlock | DocsSection> {
    // TODO Mocked documentation page content
    return []
  }

  getDocumentationMockedEditorBlockDefinitions(): Array<DocsBlockDefinition> {
    return [
      RichText,
      Title1,
      Title2,
      Title3,
      Title4,
      Title5,
      OrderedList,
      UnorderedList,
      DoDontGuidelines,
      Divider,
      Blockquote,
      Callout,
      Image,
      ShortcutLinks,
      EmbedFigma,
      Storybook,
      EmbedYoutube,
      EmbedLottie,
      Embed,
      Markdown,
      Table,
      DesignTokens,
      ColorRamps,
      AccessibilityGrid,
      Code,
      CodeReact,
      Assets,
      FigmaComponents,
      FigmaComponentPropsTable,
      FigmaFrames,
      ReleaseNotes,
      ComponentChecklist,
      ComponentChecklistAll,
      ComponentHealth,
    ]
  }
}
