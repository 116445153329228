//
//  SDKDesignComponentOrigin.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTOFigmaComponent } from "@supernova-studio/client"

import { SourceType } from "../enums/SDKSourceType"

import { Source, SourceTransportModel } from "./SDKSource"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DesignComponentOriginTransportModel = {
  sourceType: SourceType
  sourceId: string | null
  fileId: string | null
  fileName: string | null
  id: string | null
  nodeId: string | null
  name: string | null
  width: number | null
  height: number | null
}

export type DesignComponentOriginModel = NonNullable<
  DTOFigmaComponent["originComponent"]
>

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DesignComponentOrigin {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  // @ts-expect-error TS(2564): Property 'sourceType' has no initializer and is no... Remove this comment to see the full error message
  sourceType: SourceType

  sourceId: string | null

  fileId: string | null

  fileName: string | null

  id: string | null

  nodeId: string | null

  name: string | null

  width: number | null

  height: number | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    model: DesignComponentOriginModel,
    sources: Array<Source>,
    rawSourceData?: DesignComponentOriginTransportModel
  ) {
    this.sourceId = model.sourceId ?? null
    this.id = model.id ?? null
    this.nodeId = model.nodeId ?? null
    this.name = model.name ?? null
    this.fileId = null
    this.fileName = null

    if (rawSourceData) {
      this.sourceType = rawSourceData.sourceType
      this.fileId = rawSourceData.fileId
      this.fileName = rawSourceData.fileName
    } else if (model.sourceId) {
      const remoteSource = sources.filter((s) => s.id === model.sourceId)[0]

      if (remoteSource) {
        this.sourceType = remoteSource.type
        this.fileId = remoteSource.cloud?.fileId ?? null
        this.fileName = remoteSource.fileName ?? null
      }
    }

    this.width = model.width ?? null
    this.height = model.height ?? null
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Conveniences

  get remoteDesignComponentUrl(): string | undefined {
    if (this.sourceType === SourceType.figma && this.fileId && this.nodeId) {
      return `https://www.figma.com/file/${this.fileId}/${
        this.fileName ?? "unknown"
      }?node-id=${this.nodeId}`
    }

    return undefined
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON */
  toTransport(): DesignComponentOriginTransportModel {
    return {
      sourceType: this.sourceType,
      sourceId: this.sourceId,
      fileId: this.fileId,
      fileName: this.fileName,
      id: this.id,
      nodeId: this.nodeId,
      name: this.name,
      width: this.width,
      height: this.height,
    }
  }

  static fromTransport(
    transport: DesignComponentOriginTransportModel
  ): DesignComponentOrigin {
    return new DesignComponentOrigin(
      {
        id: transport.id ?? "",
        sourceId: transport.sourceId ?? "",
        nodeId: transport.nodeId ?? "",
        name: transport.name ?? "",
        width: transport.width ?? undefined,
        height: transport.height ?? undefined,
      },
      [],
      transport
    )
  }
}
