//
//  SDKSupernova.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { AreaAI } from "./areas/SDKAreaAI"
import { AreaAssets } from "./areas/SDKAreaAssets"
import { AreaAutomation } from "./areas/SDKAreaAutomation"
import { AreaBrands } from "./areas/SDKAreaBrands"
import { AreaBulkOperations } from "./areas/SDKAreaBulkOperations"
import { AreaComponents } from "./areas/SDKAreaComponents"
import { AreaDataSources } from "./areas/SDKAreaDataSources"
import { AreaDesignSystems } from "./areas/SDKAreaDesignSystems"
import { AreaDocumentation } from "./areas/SDKAreaDocumentation"
import { AreaIntegrations } from "./areas/SDKAreaIntegrations"
import { AreaMe } from "./areas/SDKAreaMe"
import { AreaMultiplayer } from "./areas/SDKAreaMultiplayer"
import { AreaNetwork } from "./areas/SDKAreaNetwork"
import { AreaRawData } from "./areas/SDKAreaRawData"
import { AreaResources } from "./areas/SDKAreaResources"
import { AreaStorage } from "./areas/SDKAreaStorage"
import { AreaTokens } from "./areas/SDKAreaTokens"
import { AreaUsers } from "./areas/SDKAreaUsers"
import { AreaVersions } from "./areas/SDKAreaVersions"
import { AreaWorkspaceIntegrations } from "./areas/SDKAreaWorkspaceIntegrations"
import { AreaWorkspaces } from "./areas/SDKAreaWorkspaces"
import { Configuration } from "./data/SDKConfiguration"
import { DataBridge, DataBridgeRequestHook } from "./data/SDKDataBridge"
import { DataCore } from "./data/SDKDataCore"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

/** Main Supernova.io SDK object. Use this to connect to your data instance and retrieve workspace / design system / version from which you can access all neccessary data. */
export class Supernova {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  private apiKey: string

  private apiUrl: string

  private apiVersion: string

  private requestHook: DataBridgeRequestHook | null

  private dataCore: DataCore

  /** Get, create, update, manipulate or delete workspaces */
  workspaces: AreaWorkspaces

  /** Get, create, update, manipulate or delete design systems */
  designSystems: AreaDesignSystems

  /** Get, create, update, manipulate or delete versions */
  versions: AreaVersions

  /** Get, create, update, manipulate or delete brands */
  brands: AreaBrands

  /** Get, create, update, manipulate or delete tokens */
  tokens: AreaTokens

  /** Get, create, update, manipulate or delete components */
  components: AreaComponents

  /** Get, create, update, manipulate or delete assets */
  assets: AreaAssets

  /** Get, create, update, manipulate or delete resources */
  resources: AreaResources

  /** Get, create, update, manipulate or delete documentation items */
  documentation: AreaDocumentation

  /** Get, create, update, manipulate or delete pipelines and automation jobs */
  automation: AreaAutomation

  /** Get, create, update, manipulate or delete users */
  users: AreaUsers

  /** Get, create, update, manipulate or delete current user */
  me: AreaMe

  /** Get, create, update, manipulate or delete integrations */
  integrations: AreaIntegrations

  /** Get, create, update, manipulate or delete workspace integrations */
  workspaceIntegrations: AreaWorkspaceIntegrations

  /** Get, create, update, manipulate or delete data sources */
  dataSources: AreaDataSources

  /** Get, create, update, manipulate or delete data from Supernova en masse */
  bulkOperations: AreaBulkOperations

  /** Read and write data using Supernova key-value storage */
  storage: AreaStorage

  /** Get access to network in exporter packages */
  network: AreaNetwork

  /** Get access to multiplayer capabilities */
  multiplayer: AreaMultiplayer

  /** Get access to AI capabilities */
  ai: AreaAI

  /** Get access to raw data for local-only restoration of data */
  raw: AreaRawData

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  /**
   * Create new Supernova.io SDK object.
   * @param apiKey API key to use for authentication
   * @param options Options for SDK
   * @param options.url URL of the Supernova.io instance to connect to. Defaults to production environment
   * @param options.requestHook Hook for observing requests
   * @param options.bypassEnvFetch When enabled, internal node-fetch lib will be used in environments when fetch is not available. Defaults to false
   */
  constructor(
    apiKey: string,
    options?: {
      apiUrl?: string
      requestHook?: DataBridgeRequestHook
      bypassEnvFetch?: boolean
      proxyUrl?: string
    }
  ) {
    this.requestHook = options?.requestHook ?? null
    this.apiKey = apiKey
    this.apiUrl = options?.apiUrl ?? Configuration.apiUrlForDefaultEnvironment()
    this.apiVersion = Configuration.apiVersionForDefaultEnvironment()
    this.dataCore = new DataCore(
      new DataBridge({
        apiUrl: this.apiUrl,
        apiVersion: this.apiVersion,
        accessToken: this.apiKey,
        bypassEnvFetch: options?.bypassEnvFetch ?? false,
        target: null,
        requestHook: this.requestHook,
        proxyUrl: options?.proxyUrl,
      })
    )
    this.workspaces = new AreaWorkspaces(this.dataCore)
    this.designSystems = new AreaDesignSystems(this.dataCore)
    this.versions = new AreaVersions(this.dataCore)
    this.brands = new AreaBrands(this.dataCore)
    this.me = new AreaMe(this.dataCore)
    this.tokens = new AreaTokens(this.dataCore)
    this.components = new AreaComponents(this.dataCore)
    this.assets = new AreaAssets(this.dataCore)
    this.resources = new AreaResources(this.dataCore)
    this.documentation = new AreaDocumentation(this.dataCore)
    this.automation = new AreaAutomation(this.dataCore)
    this.users = new AreaUsers(this.dataCore)
    this.integrations = new AreaIntegrations(this.dataCore)
    this.workspaceIntegrations = new AreaWorkspaceIntegrations(this.dataCore)
    this.dataSources = new AreaDataSources(this.dataCore)
    this.bulkOperations = new AreaBulkOperations(this.dataCore)
    this.storage = new AreaStorage(this.dataCore)
    this.network = new AreaNetwork(this.dataCore)
    this.multiplayer = new AreaMultiplayer(this.dataCore)
    this.ai = new AreaAI(this.dataCore)
    this.raw = new AreaRawData(this.dataCore)
  }

  get apiKeySet(): boolean {
    return this.apiKey.length > 0
  }
}
