//
//  AreaRawData.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Asset } from "../../model/assets/SDKAsset"
import { Brand, BrandRemoteModel } from "../../model/base/SDKBrand"
import {
  DesignSystem, // @ts-expect-error TS(2305): Module '"../../model/base/SDKDesignSystem"' has no exported member named 'DesignSystemRemoteModel'.
  DesignSystemRemoteModel,
} from "../../model/base/SDKDesignSystem"
import {
  DesignSystemVersion,
  DesignSystemVersionRemoteModel,
} from "../../model/base/SDKDesignSystemVersion"
import {
  Component,
  ComponentRemoteModel,
} from "../../model/components/SDKComponent"
import { DesignComponentRemoteModel } from "../../model/components/SDKDesignComponent"
import {
  ElementDataView,
  ElementDataViewRemoteModel,
} from "../../model/elements/SDKElementDataView"
import {
  ElementProperty,
  ElementPropertyRemoteModel,
} from "../../model/elements/SDKElementProperty"
import {
  ElementPropertyValue,
  ElementPropertyValueRemoteModel,
} from "../../model/elements/values/SDKElementPropertyValue"
import { AssetGroup } from "../../model/groups/SDKAssetGroup"
import {
  ComponentGroup,
  ComponentGroupRemoteModel,
} from "../../model/groups/SDKComponentGroup"
import { DesignComponentGroupRemoteModel } from "../../model/groups/SDKDesignComponentGroup"
import {
  TokenGroup,
  TokenGroupRemoteModel,
} from "../../model/groups/SDKTokenGroup"
import {
  Membership,
  MembershipRemoteModel,
} from "../../model/membership/SDKMembership"
import { Source, SourceRemoteModel } from "../../model/support/SDKSource"
import { Token } from "../../model/tokens/SDKToken"
import { TokenRemoteModel } from "../../model/tokens/remote/SDKRemoteTokenModel"
import { DataCore } from "../data/SDKDataCore"
import { AssetGroupResolver } from "../resolvers/SDKAssetGroupResolver"
import { ComponentGroupResolver } from "../resolvers/SDKComponentGroupResolver"
import { TokenGroupResolver } from "../resolvers/SDKTokenGroupResolver"
import { TokenResolver } from "../resolvers/SDKTokenResolver"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Raw Data Area

export class AreaRawData {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Fetch raw data

  /** Fetches raw data necessary for construction of components */
  getRawComponentModelData(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ComponentRemoteModel>> {
    return this.dataCore.read.getRemoteComponents(designSystemId, versionId)
  }

  /** Fetches raw data necessary for component groups */
  getRawComponentGroupModelData(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ComponentGroupRemoteModel>> {
    return this.dataCore.read.getRemoteComponentGroups(
      designSystemId,
      versionId
    )
  }

  /** Fetches raw data necessary for assets */
  getRawAssetModelData(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DesignComponentRemoteModel>> {
    return this.dataCore.read.getRemoteAssets(designSystemId, versionId)
  }

  /** Fetches raw data necessary for asset groups */
  getRawAssetGroupModelData(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DesignComponentGroupRemoteModel>> {
    return this.dataCore.read.getRemoteAssetGroups(designSystemId, versionId)
  }

  /** Fetches raw data necessary for tokens */
  getRawTokenModelData(
    designSystemId: string,
    versionId: string
  ): Promise<Array<TokenRemoteModel>> {
    return this.dataCore.read.getRemoteTokens(designSystemId, versionId)
  }

  /** Fetches raw data necessary for token groups */
  getRawTokenGroupModelData(
    designSystemId: string,
    versionId: string
  ): Promise<Array<TokenGroupRemoteModel>> {
    return this.dataCore.read.getRemoteTokenGroups(designSystemId, versionId)
  }

  /** Fetches raw data necessary for elements */
  async getRawElementModelData(
    designSystemId: string,
    versionId: string
  ): Promise<{
    propertyValues: Array<ElementPropertyValueRemoteModel>
    propertyViews: Array<ElementDataViewRemoteModel>
    propertyDefinitions: Array<ElementPropertyRemoteModel>
  }> {
    const result = await Promise.all([
      this.dataCore.read.getRemoteElementPropertyValues(
        designSystemId,
        versionId
      ),
      this.dataCore.read.getRemoteElementDataViewDefinitions(
        designSystemId,
        versionId
      ),
      this.dataCore.read.getRemoteElementPropertyDefinitions(
        designSystemId,
        versionId
      ),
    ])

    return {
      propertyValues: result[0],
      propertyViews: result[1],
      propertyDefinitions: result[2],
    }
  }

  /** Fetches raw source data */
  getRawSourceModelData(
    designSystemId: string
  ): Promise<Array<SourceRemoteModel>> {
    return this.dataCore.read.getRemoteSources(designSystemId)
  }

  /** Fetches raw membership */
  getRawMembershipModelData(
    userId: string
  ): Promise<Array<MembershipRemoteModel>> {
    return this.dataCore.read.getRemoteMemberships(userId)
  }

  /** Fetches raw design systems */
  getRawDesignSystemModelData(
    workspaceId: string
  ): Promise<Array<DesignSystemRemoteModel>> {
    return this.dataCore.read.getRemoteDesignSystems(workspaceId)
  }

  /** Fetches raw versions */
  getRawVersionModelData(
    designSystemId: string
  ): Promise<Array<DesignSystemVersionRemoteModel>> {
    return this.dataCore.read.getVersions(designSystemId)
  }

  /** Fetches raw brands */
  getRawBrandModelData(
    designSystemId: string,
    versionId: string
  ): Promise<Array<BrandRemoteModel>> {
    return this.dataCore.read.getBrands(designSystemId, versionId)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Build objects out of raw data

  /** Builds components from raw data */
  buildComponentsFromRaw(
    rawData: Array<ComponentRemoteModel>,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): Array<Component> {
    return rawData.map(
      (component) => new Component(component, properties, propertyValues)
    )
  }

  /** Build component groups */
  buildComponentGroupsFromRaw(
    rawData: Array<ComponentGroupRemoteModel>,
    components: Array<Component>
  ): Array<ComponentGroup> {
    const resolver = new ComponentGroupResolver(components)
    const groups = resolver.resolveGroupData(rawData)
    return groups
  }

  /** Builds assets from raw data */
  buildAssetsFromRaw(
    rawData: Array<DesignComponentRemoteModel>,
    sources: Array<Source>
  ): Array<Asset> {
    // Manage duplicates
    const assetNames = new Map<string, number>()

    for (const item of rawData) {
      if (item.exportProperties.isAsset) {
        assetNames.set(item.meta.name.toLowerCase(), 0)
      }
    }

    // Transform only exportable designComponents into assets
    const assets: Array<Asset> = []

    for (const asset of rawData) {
      const lowercasedName = asset.meta.name.toLowerCase()

      if (asset.exportProperties.isAsset) {
        // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        assets.push(new Asset(asset, assetNames.get(lowercasedName), sources))
        // Increase number of duplicates
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        assetNames.set(lowercasedName, assetNames.get(lowercasedName) + 1)
      }
    }

    return assets
  }

  /** Build asset groups */
  buildAssetGroupsFromRaw(
    rawData: Array<DesignComponentGroupRemoteModel>,
    assets: Array<Asset>
  ): Array<AssetGroup> {
    const resolver = new AssetGroupResolver(assets)
    const result = resolver.resolveGroupData(rawData)

    return result
  }

  /** Builds tokens from raw data */
  buildTokensFromRaw(
    rawData: Array<TokenRemoteModel>,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>,
    propertyViews: Array<ElementDataView>,
    tokenGroups: Array<TokenGroup>,
    sources: Array<Source>,
    designSystemId: string,
    versionId: string
  ): Array<Token> {
    const resolver = new TokenResolver(designSystemId, versionId)
    const result = resolver.resolveTokenData(
      rawData,
      tokenGroups,
      properties,
      propertyViews,
      propertyValues,
      sources
    )
    return result
  }

  /** Build token groups */
  buildTokenGroupsFromRaw(
    rawData: Array<TokenGroupRemoteModel>
  ): Array<TokenGroup> {
    const resolver = new TokenGroupResolver()
    const result = resolver.resolveGroupData(rawData)
    return result
  }

  /** Builds elements from raw data */
  buildElementsFromRaw(rawData: {
    propertyValues: Array<ElementPropertyValueRemoteModel>
    propertyViews: Array<ElementDataViewRemoteModel>
    propertyDefinitions: Array<ElementPropertyRemoteModel>
  }): {
    propertyValues: Array<ElementPropertyValue>
    propertyViews: Array<ElementDataView>
    propertyDefinitions: Array<ElementProperty>
  } {
    return {
      propertyValues: rawData.propertyValues.map(
        (value) => new ElementPropertyValue(value)
      ),
      propertyViews: rawData.propertyViews.map(
        (view) => new ElementDataView(view)
      ),
      propertyDefinitions: rawData.propertyDefinitions.map(
        (definition) => new ElementProperty(definition)
      ),
    }
  }

  /** Build sources */
  buildSourcesFromRaw(rawData: Array<SourceRemoteModel>): Array<Source> {
    return rawData.map((source) => new Source(source))
  }

  /** Builds memberships */
  buildMembershipsFromRaw(
    rawData: Array<MembershipRemoteModel>
  ): Array<Membership> {
    return rawData.map((membership) => new Membership(membership))
  }

  /** Builds design systems */
  buildDesignSystemsFromRaw(
    rawData: Array<DesignSystemRemoteModel>
  ): Array<DesignSystem> {
    return rawData.map((designSystem) => new DesignSystem(designSystem))
  }

  /** Builds versions */
  buildVersionsFromRaw(
    rawData: Array<DesignSystemVersionRemoteModel>
  ): Array<DesignSystemVersion> {
    return rawData.map((version) => new DesignSystemVersion(version))
  }

  /** Builds brands */
  buildBrandsFromRaw(rawData: Array<BrandRemoteModel>): Array<Brand> {
    return rawData.map((brand) => new Brand(brand))
  }
}
