//
//  AreaAI.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DataCore } from "../data/SDKDataCore"

import { RemoteVersionIdentifier, RemoteWorkspaceVersionIdentifier } from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - AI Area

export class AreaAI {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - The Forge

  /** Generates code for the associated design system components for provided user prompt
   * @param from - Design system to use
   * @param prompt - Prompt to generate code for
   * @param components - Components to generate code for
   * @returns Forged component code
   */
  async forgeCode(
    from: RemoteVersionIdentifier,
    prompt: string,
    components: Array<any>
  ): Promise<string> {
    return this.dataCore.forgeCode(
      from.designSystemId,
      from.versionId,
      prompt,
      components
    )
  }

  /** Reforges code for the associated design system components for provided user prompt, taking into account existing code
   * @param from - Design system to use
   * @param prompt - Prompt to generate code for
   * @param components - Components to generate code for
   * @param existingCode - Existing code to reforge
   * @returns Reforged component code
   */
  async reforgeCode(
    from: RemoteVersionIdentifier,
    prompt: string,
    components: Array<any>,
    existingCode: string
  ): Promise<string> {
    return this.dataCore.reforgeCode(
      from.designSystemId,
      from.versionId,
      prompt,
      components,
      existingCode
    )
  }

  /** Reforges code for the associated design system components for provided user prompt, taking into account existing code
   * @param from - Design system to use
   * @param prompt - Prompt to generate code for
   * @param components - Components to generate code for
   * @param existingCode - Existing code to reforge
   * @returns Reforged component code
   */
  async proposeTitle(
    from: RemoteVersionIdentifier,
    firstForgeQuery: string
  ): Promise<string> {
    return this.dataCore.proposeTitle(
      from.designSystemId,
      from.versionId,
      firstForgeQuery
    )
  }

  /** Answers question based on the data of design system in natural language */
    async answerQuestion(
        from: RemoteWorkspaceVersionIdentifier,
        persona: "designer" | "developer" | "code",
        askQuery: string
    ): Promise<string> {
        return this.dataCore.answerQuestion(
        from.workspaceId,
        from.designSystemId,
        from.versionId,
        persona,
        askQuery
        )
    }
  
    /** Indexes a design system so it can be asked in natural language */
    async indexDS(
        from: RemoteWorkspaceVersionIdentifier
    ): Promise<string> {
        return this.dataCore.indexDS(
        from.workspaceId,
        from.designSystemId,
        from.versionId
        )
    }
}