//
//  AreaAssets.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTOFigmaNode, DTOFigmaNodeV2 } from "@supernova-studio/client"

import {
  DocsImageRef,
  DocsImageRefType,
  ResourceAsset,
  ResourceFont,
} from "../../exports"
import { DataCore } from "../data/SDKDataCore"

import { RemoteVersionIdentifier } from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaResources {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches all remote asset resources in the version
   * @param from - Remote version to fetch from
   * @returns All image resources in the specified version
   */
  async getAssetResources(
    from: RemoteVersionIdentifier
  ): Promise<Array<ResourceAsset>> {
    return this.dataCore.assetResources(from.designSystemId, from.versionId)
  }

  /** Fetches all remote font resources in the version
   * @param from - Remote version to fetch from
   * @returns All font resources in the specified version
   */
  async getFontResources(
    from: RemoteVersionIdentifier
  ): Promise<Array<ResourceFont>> {
    return this.dataCore.fontResources(from.designSystemId, from.versionId)
  }

  /** Fetches all Figma frames that were rendered font resources in the version
   * @param from - Remote version to fetch from
   * @returns All font resources in the specified version
   */
  async getFigmaFrames(
    from: RemoteVersionIdentifier
  ): Promise<Array<DTOFigmaNode>> {
    return this.dataCore.figmaFrames(from.designSystemId, from.versionId)
  }

  /** Fetches all Figma frames that were rendered font resources in the version (V2 of the API)
   * @param from - Remote version to fetch from
   * @returns All font resources in the specified version
   */
  async getFigmaFramesV2(
    from: RemoteVersionIdentifier
  ): Promise<Array<DTOFigmaNodeV2>> {
    return this.dataCore.figmaFramesV2(from.designSystemId, from.versionId)
  }

  /**
   * @param from - Remote version to fetch from
   * @param frameReferenceId  - Unique stable identifier of the Figma frame (Supernova id, not Figma id)
   * @returns URL of the hosted image of the Figma frame that is stable across updates
   */
  getFigmaFrameHostedUrl(
    from: RemoteVersionIdentifier,
    frameReferenceId: string
  ): string {
    const resourceFragment = `figma-nodes/${frameReferenceId}/image-url`
    return this.dataCore
      .getBridgeInternal()
      .getVersionEndpoint(from.designSystemId, from.versionId, resourceFragment)
  }

  /** Retrieves stable URL
   * @param from - Remote version to fetch from
   * @param frameReferenceId  - Unique stable identifier of the Figma frame (Supernova id, not Figma id)
   * @returns URL of the hosted image resource, either uploaded file or Figma frame
   */
  getImageRefHostedUrl(
    from: RemoteVersionIdentifier,
    imageRef: DocsImageRef | undefined
  ): string | undefined {
    if (!imageRef) {
      return undefined
    }
    if (imageRef.type === DocsImageRefType.figmaNode && imageRef.figmaNode) {
      return this.getFigmaFrameHostedUrl(
        from,
        imageRef.figmaNode.frameReferenceId
      )
    }
    if (imageRef.type === DocsImageRefType.resource && imageRef.resource?.url) {
      return imageRef.resource.url
    }
    return undefined
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Uploads new asset resource */
  async uploadAssetResource(
    from: RemoteVersionIdentifier,
    asset: File
  ): Promise<ResourceAsset> {
    return this.dataCore.uploadAssetResource(
      from.designSystemId,
      from.versionId,
      asset
    )
  }

  /** Uploads new font resource */
  async uploadFontResource(
    from: RemoteVersionIdentifier,
    font: File
  ): Promise<ResourceFont | null> {
    return this.dataCore.uploadFontResource(
      from.designSystemId,
      from.versionId,
      font
    )
  }

  /** Uploads new font resources */
  async uploadFontResources(
    from: RemoteVersionIdentifier,
    fonts: FileList
  ): Promise<ResourceFont[]> {
    return this.dataCore.uploadFontResources(
      from.designSystemId,
      from.versionId,
      fonts
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  /** Deletes single resource of any type */
  async deleteResource(
    from: RemoteVersionIdentifier,
    resourceId: string
  ): Promise<void> {
    return this.dataCore.deleteResource(
      from.designSystemId,
      from.versionId,
      resourceId
    )
  }

  /** Deletes multiple resource of any type */
  async deleteResources(
    from: RemoteVersionIdentifier,
    resourceIds: string[]
  ): Promise<void> {
    return this.dataCore.deleteResources(
      from.designSystemId,
      from.versionId,
      resourceIds
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Compute
}
