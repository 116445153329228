//
//  SDKUser.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  UserProfile,
  UserProfileRemoteModel,
  UserProfileTransportModel,
  UserThemeRemoteModel,
} from "./SDKUserProfile"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

type UserSource = "SignUp" | "Invite" | "SSO"

export type UserRemoteModel = {
  id: string
  email: string
  source: UserSource | null
  createdAt: string
  profile: UserProfileRemoteModel
  theme?: UserThemeRemoteModel
  linkedIntegrations?: LinkedIntegrations
}

export type LinkedIntegrations = {
  figma?: UserLinkedIntegration
  github?: UserLinkedIntegration[]
  azure?: UserLinkedIntegration[]
  gitlab?: UserLinkedIntegration[]
  bitbucket?: UserLinkedIntegration[]
}

type UserLinkedIntegration = {
  id: string
  email?: string
  handle?: string
  authType?: "OAuth2" | "PAT"
  avatarUrl?: string
  customUrl?: string
}

export type UserTransportModel = Pick<User, "id" | "email" | "source"> & {
  createdAt: string
  profile: UserProfileTransportModel
  linkedIntegrations?: LinkedIntegrations
}

export type UserAvatarUpdateModel = {
  avatar?: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -- - --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class User {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Unique user identifier */
  id: string

  /** Unique user email */
  email: string

  /** When was the user created */
  createdAt: Date | null

  /** User profile */
  profile: UserProfile

  /** Source of user account */
  source: UserSource | null

  /** Linked integrations */
  linkedIntegrations: LinkedIntegrations | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: UserRemoteModel) {
    this.id = model.id
    this.email = model.email
    this.source = model.source
    this.profile = new UserProfile(model.profile)
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.linkedIntegrations = model.linkedIntegrations ?? null
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): UserRemoteModel {
    return {
      id: this.id,
      email: this.email,
      source: this.source,
      profile: this.profile.toRemote(),
      createdAt: this.createdAt ? this.createdAt.toISOString() : "",
      // @ts-expect-error TS(2322): Type 'LinkedIntegrations | null' is not assignable... Remove this comment to see the full error message
      linkedIntegrations: this.linkedIntegrations,
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): UserTransportModel {
    return {
      id: this.id,
      email: this.email,
      source: this.source,
      profile: this.profile.toTransport(),
      createdAt: this.createdAt ? this.createdAt.toISOString() : "",
      // @ts-expect-error TS(2322): Type 'LinkedIntegrations | null' is not assignable... Remove this comment to see the full error message
      linkedIntegrations: this.linkedIntegrations,
    }
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: UserTransportModel) {
    return new User(model)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Helpers

  /** Returns user's name which should be used in read-only cases. */
  get displayName(): string {
    return this.profile.name || this.email
  }

  /** Returns user's initials */
  get initials(): string {
    const names = this.displayName.split(" ")
    if (names.length === 0) {
      return ""
    }
    let initials = (names[0] ?? "").substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += (names[names.length - 1] ?? "").substring(0, 1).toUpperCase()
    }
    return initials
  }

  get firstName(): string | undefined {
    return this.profile.name.split(" ")[0]
  }
}
