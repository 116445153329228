//
//  AreaAssets.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTOFigmaNode,
  DTOFigmaNodeRenderFormat,
  DTOFigmaNodeRenderIdInput,
  DTOFigmaNodeRenderInput,
} from "@supernova-studio/client"

import { Asset } from "../../model/assets/SDKAsset"
import { RenderedAsset } from "../../model/assets/SDKRenderedAsset"
import { AssetFormat } from "../../model/enums/SDKAssetFormat"
import { AssetScale } from "../../model/enums/SDKAssetScale"
import { AssetGroup } from "../../model/groups/SDKAssetGroup"
import { DataCore } from "../data/SDKDataCore"
import { RenderedAssetRequestSettings } from "../data/requests/SDKDataCoreRenderRequests"

import { RemoteVersionIdentifier } from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaAssets {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches all remote assets in the version
   * @param from - Remote version to fetch from
   * @returns All assets in the specified version
   */
  async getAssets(from: RemoteVersionIdentifier): Promise<Array<Asset>> {
    return this.dataCore.assets(from.designSystemId, from.versionId)
  }

  /** Fetches all remote asset groups in the version
   * @param from - Remote version to fetch from
   * @returns All asset groups in the specified version
   */
  async getAssetGroups(
    from: RemoteVersionIdentifier
  ): Promise<Array<AssetGroup>> {
    return this.dataCore.assetGroups(from.designSystemId, from.versionId)
  }

  /** Renders all assets in this version and retrieves URLs from which assets can be downloaded as key-value.
   *
   * You can only render one combination of size/format with one request - use more requests if you need to render more. Assets that are rendered as "png" will use "scale" attribute, however, when the format is "svg" or "pdf", scale attribute is ignored and will always render the original size.
   *
   * Note that assets are not persistent and URLs will expire quickly - you must download them and store them locally / remotely and can never use this URL publicly as it won't work after a short time
   * @param from - Remote version to fetch from
   * @param assets - Assets to render
   * @param groups - All asset groups in the version
   * @param format - Format to render (png, svg, pdf)
   * @param scale - Scale to render (1x, 2x, 3x, 4x)
   * @returns All assets that were provided in the request, rendered in the specified format and scale
   */
  async getRenderedAssets(
    from: RemoteVersionIdentifier,
    assets: Array<Asset>,
    groups: Array<AssetGroup>,
    format: AssetFormat,
    scale: AssetScale
  ): Promise<Array<RenderedAsset>> {
    // Construct rendering request. Only one size can be rendered at once
    const settings: RenderedAssetRequestSettings = [
      {
        format,
        scale,
        prefix: "",
        suffix: "",
      },
    ]

    return this.dataCore.renderedAssets(
      from.designSystemId,
      from.versionId,
      assets,
      groups,
      settings
    )
  }

  /** Renders figma frames based on the source and node id. The result is a URL to the hosted image of the Figma frame that is stable across updates.
   *
   * @returns Rendered Figma frames
   */
  async getRenderedFigmaFramesAsync(
    from: RemoteVersionIdentifier,
    nodesToRender: Array<DTOFigmaNodeRenderInput>
  ): Promise<Array<DTOFigmaNode>> {
    return this.dataCore.renderedFigmaFramesAsync(
      from.designSystemId,
      from.versionId,
      nodesToRender
    )
  }

  /** Renders figma frames based on the source and node id. The result is a URL to the hosted image of the Figma frame that is stable across updates.
   *
   * @returns Rendered Figma frames
   */
  async getRenderedFigmaFrames(
    from: RemoteVersionIdentifier,
    framesToRender: Array<DTOFigmaNodeRenderIdInput>
  ): Promise<Array<DTOFigmaNode>> {
    return this.dataCore.renderedFigmaFrames(
      from.designSystemId,
      from.versionId,
      framesToRender
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Compute
}
