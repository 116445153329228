//
//  Supernova SDK
//  Pulsar Language
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DesignComponentRemoteModel } from "../components/SDKDesignComponent"
import {
  DesignComponentOrigin,
  DesignComponentOriginTransportModel,
} from "../support/SDKDesignComponentOrigin"
import { Source } from "../support/SDKSource"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export type AssetTransportModel = {
  id: string
  brandId: string
  designSystemVersionId: string
  thumbnailUrl: string | null
  svgUrl: string | null
  name: string
  description: string
  componentId: string | null
  previouslyDuplicatedNames: number
  origin: DesignComponentOriginTransportModel | null
  createdAt: string
  updatedAt: string
  exportProperties: {
    isAsset: boolean
  }
}

export class Asset {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  brandId: string

  designSystemVersionId: string

  thumbnailUrl: string | null

  svgUrl: string | null

  name: string

  description: string | null

  componentId: string | null

  previouslyDuplicatedNames: number

  origin: DesignComponentOrigin | null

  createdAt: Date | null

  updatedAt: Date | null

  isAsset: boolean

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    model: DesignComponentRemoteModel,
    duplicates: number,
    sources: Array<Source>
  ) {
    this.id = model.persistentId
    this.brandId = model.brandId
    this.designSystemVersionId = model.designSystemVersionId
    this.thumbnailUrl = model.thumbnailUrl ?? null
    this.svgUrl = model.svgUrl ?? null
    this.thumbnailUrl = model.thumbnailUrl ?? null
    this.previouslyDuplicatedNames = duplicates
    this.name = model.meta.name
    this.description = model.meta.description ?? null
    this.componentId = model.id
    this.origin = model.originComponent
      ? new DesignComponentOrigin(model.originComponent, sources)
      : null
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
    this.isAsset = model.exportProperties.isAsset
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON */
  toTransport(): AssetTransportModel {
    return {
      id: this.id,
      brandId: this.brandId,
      designSystemVersionId: this.designSystemVersionId,
      thumbnailUrl: this.thumbnailUrl,
      svgUrl: this.svgUrl,
      name: this.name,
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      description: this.description,
      componentId: this.componentId,
      previouslyDuplicatedNames: this.previouslyDuplicatedNames,
      origin: this.origin, // Assuming DesignComponentOrigin is already serializable
      createdAt: this.createdAt ? this.createdAt.toISOString() : "",
      updatedAt: this.updatedAt ? this.updatedAt.toISOString() : "",
      exportProperties: {
        isAsset: this.isAsset,
      },
    }
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: AssetTransportModel): Asset {
    const origin = model.origin
      ? DesignComponentOrigin.fromTransport(model.origin)
      : null
    const fakeDesignComponentRemoteModel: DesignComponentRemoteModel = {
      persistentId: model.id,
      brandId: model.brandId,
      designSystemVersionId: model.designSystemVersionId,
      thumbnailUrl: model.thumbnailUrl ?? undefined,
      svgUrl: model.svgUrl ?? undefined,
      meta: {
        name: model.name,
        description: model.description,
      },
      id: model.componentId!,
      parsedOriginComponent: model.origin
        ? DesignComponentOrigin.fromTransport(model.origin)
        : undefined,
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'Date'.
      createdAt: model.createdAt,
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'Date'.
      updatedAt: model.updatedAt,
      exportProperties: {
        isAsset: model.exportProperties.isAsset,
      },
      properties: [],
    }

    return new Asset(
      fakeDesignComponentRemoteModel,
      model.previouslyDuplicatedNames,
      []
    )
  }
}
