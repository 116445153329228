/* eslint-disable max-lines */
//
//  SDKDataCoreAIRequests.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2024 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DataBridge } from "../SDKDataBridge"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Request engine: AI

export class DataCoreAIRequests {
  private bridge: DataBridge

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(bridge: DataBridge) {
    this.bridge = bridge
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - User

  /** Remote: Forges code based on user prompt */
  async forgeCode(
    designSystemId: string,
    versionId: string,
    prompt: string,
    components: Array<any>
  ): Promise<string> {
    // This is only temporary, hard-coded endpoint for now. Should be replaced with real AI backend endpoint soon
    const endpoint =
      "https://nfjioixxqr2pwjytabr5fgkn4y0iklvf.lambda-url.eu-west-1.on.aws/"

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const result = await this.bridge.post(endpoint, {
      components,
      question: prompt,
      environment: "dev",
    })

    return result?.answer
  }

  /** Remote: Reforges code based on user prompt */
  async reforgeCode(
    designSystemId: string,
    versionId: string,
    prompt: string,
    components: Array<any>,
    existingCode: string
  ): Promise<string> {
    // This is only temporary, hard-coded endpoint for now. Should be replaced with real AI backend endpoint soon
    const endpoint =
      "https://d7lrfnfmu5h4mnn64xqjsypqwm0gstia.lambda-url.eu-west-1.on.aws/"

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const result = await this.bridge.post(endpoint, {
      components,
      question: prompt,
      code: existingCode,
      environment: "dev",
    })

    return result?.answer
  }

  /** Remote: Proposes title for the forged code */
  async proposeTitle(
    designSystemId: string,
    versionId: string,
    firstForgeQuery: string
  ): Promise<string> {
    // This is only temporary, hard-coded endpoint for now. Should be replaced with real AI backend endpoint soon
    const endpoint =
      "https://4xcuesqh5aipa6oehiwe24ucmi0logwm.lambda-url.eu-west-1.on.aws/"

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const result = await this.bridge.post(endpoint, {
      question: firstForgeQuery,
      environment: "dev",
    })

    return result?.answer
  }

  /** Remote: Answers question based on the data of design system in natural language */
  async answerQuestion(
    workspaceId: string,
    designSystemId: string,
    versionId: string,
    persona: "designer" | "developer" | "code",
    askQuery: string
  ): Promise<string> {
    // This is only temporary, hard-coded endpoint for now. Should be replaced with real AI backend endpoint soon
    const endpoint =
      `https://ai.demo.supernova.io/api/ws/${workspaceId}/ds/${designSystemId}/version/${versionId}/search/answer`

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const result = await this.bridge.post(endpoint, {
      question: askQuery,
      environment: "dev",
      persona,
    })

    return result?.answer
  }

  /** Remote: Index a design system so it can be asked in natural language */
  async indexDS(
    workspaceId: string,
    designSystemId: string,
    versionId: string,
  ): Promise<string> {
    // This is only temporary, hard-coded endpoint for now. Should be replaced with real AI backend endpoint soon
    const endpoint =
      `https://ai.demo.supernova.io/api/ws/${workspaceId}/ds/${designSystemId}/version/${versionId}/search/index`

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const result = await this.bridge.post(endpoint, {
      environment: "dev",
    })

    return result?.answer
  }
}