/* eslint-disable max-lines */
//
//  SDKDataCore.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2020 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTODesignElementsDataDiffResponse,
  DTODesignSystem,
  DTODesignSystemContactsResponse,
  DTODesignSystemMemberListResponse,
  DTODesignSystemVersionStats,
  DTODesignSystemVersionStatsQuery,
  DTODocumentationLinkPreviewRequest,
  DTODocumentationLinkPreviewResponse,
  DTODocumentationPublishTypeQueryParams,
  DTOFigmaNode,
  DTOFigmaNodeV2,
  DTOGetBlockDefinitionsOutput,
  DTOGetDocumentationPageAnchorsResponse,
  DTOPageRedirectListResponse,
  DTOPipeline,
  DTOTokenCollection,
} from "@supernova-studio/client"
import { PageRedirect } from "@supernova-studio/model"

import { AclRemoteModel } from "../../../model/acl/SDKAcl"
import { BootstrapDataRemoteModel } from "../../../model/base/SDKBootstrapData"
import { BrandRemoteModel } from "../../../model/base/SDKBrand"
import {
  DesignSystemVersionCreationJobRemoteModel,
  DesignSystemVersionRemoteModel,
} from "../../../model/base/SDKDesignSystemVersion"
import { DocumentationLegacyModel } from "../../../model/base/SDKDocumentationLegacy"
import { ComponentRemoteModel } from "../../../model/components/SDKComponent"
import { DesignComponentRemoteModel } from "../../../model/components/SDKDesignComponent"
import { DocsBlockDefinitionModel } from "../../../model/docs/blocks/definition/SDKDocsBlockDefinition"
import { DocsEntityGroupModel } from "../../../model/docs/entities/SDKDocsEntityGroup"
import { DocsEntityPageModel } from "../../../model/docs/entities/SDKDocsEntityPage"
import { DocumentationLegacyPageModel } from "../../../model/documentation/SDKDocumentationLegacyPage"
import { ElementDataViewRemoteModel } from "../../../model/elements/SDKElementDataView"
import { ElementPropertyRemoteModel } from "../../../model/elements/SDKElementProperty"
import { ElementPropertyValueRemoteModel } from "../../../model/elements/values/SDKElementPropertyValue"
import { DocumentationEnvironment } from "../../../model/enums/SDKDocumentationEnvironment"
import { TokenType } from "../../../model/enums/SDKTokenType"
import { AuthenticationTokens } from "../../../model/exporters/SDKConnections"
import {
  ExporterMembershipModel,
  ExporterModel,
  ExporterProvider,
} from "../../../model/exporters/SDKExporter"
import { ExporterCustomBlockModel } from "../../../model/exporters/custom_blocks/SDKExporterCustomBlock"
import {
  ExportBuildModel,
  ExportBuildStatus,
} from "../../../model/exporters/exporter_builds/SDKExportBuild"
import { ExportBuildResultLogModel } from "../../../model/exporters/exporter_builds/SDKExportBuildResults"
import { ComponentGroupRemoteModel } from "../../../model/groups/SDKComponentGroup"
import { DesignComponentGroupRemoteModel } from "../../../model/groups/SDKDesignComponentGroup"
import { TokenGroupRemoteModel } from "../../../model/groups/SDKTokenGroup"
import { ImportJobRemoteModel } from "../../../model/jobs/SDKImportJob"
import { MembershipRemoteModel } from "../../../model/membership/SDKMembership"
import { ResourceAssetModel } from "../../../model/resources/SDKResourceAsset"
import { ResourceFontModel } from "../../../model/resources/SDKResourceFont"
import { CustomDomainRemoteModel } from "../../../model/support/SDKCustomDomain"
import { FigmaStructureModel } from "../../../model/support/SDKFigmaStructure"
import { FigmaStructureDetailModel } from "../../../model/support/SDKFigmaStructureDetail"
import { SourceRemoteModel } from "../../../model/support/SDKSource"
import { TokenThemeRemoteModel } from "../../../model/themes/SDKTokenTheme"
import { TokenRemoteModel } from "../../../model/tokens/remote/SDKRemoteTokenModel"
import { UserRemoteModel } from "../../../model/users/SDKUser"
import { UserMembershipRemoteModel } from "../../../model/users/SDKUserMembership"
import { UserWorkspaceNotificationSettingsRemoteModel } from "../../../model/users/SDKUserWorkspaceNotificationSettings"
import { WorkspaceRemoteModel } from "../../../model/workspaces/SDKWorkspace"
import { WorkspaceIPWhitelistSettingsRemoteModel } from "../../../model/workspaces/SDKWorkspaceIPWhitelistSettings"
import { WorkspaceIntegrationsRemoteModel } from "../../../model/workspaces/SDKWorkspaceIntegration"
import {
  GitProviderValues,
  WorkspaceIntegrationGetGitProvidersInput,
} from "../../../model/workspaces/SDKWorkspaceIntegrationGetGitProviders"
import {
  OAuth2IntegrationTypes,
  WorkspaceIntegrationOauthRequestRemoteModel,
} from "../../../model/workspaces/SDKWorkspaceIntegrationOauthRequest"
import { WorkspaceInvitationRemoteModel } from "../../../model/workspaces/SDKWorkspaceInvitation"
import { WorkspaceInvoiceRemoteModel } from "../../../model/workspaces/SDKWorkspaceInvoice"
import { WorkspaceNPMRegistryAccessTokenRemoteModel } from "../../../model/workspaces/SDKWorkspaceNPMRegistryAccessToken"
import { WorkspaceNPMRegistrySettingsRemoteModel } from "../../../model/workspaces/SDKWorkspaceNPMRegistrySettings"
import { WorkspaceOnboardingRemoteModel } from "../../../model/workspaces/SDKWorkspaceOnboarding"
import { WorkspaceProductRemoteModel } from "../../../model/workspaces/SDKWorkspaceProduct"
import { SubscriptionProductFeaturesRemoteModel } from "../../../model/workspaces/SDKWorkspaceProductFeatures"
import { WorkspaceSSOSettingsRemoteModel } from "../../../model/workspaces/SDKWorkspaceSSOSettings"
import { WorkspaceSubscriptionRemoteModel } from "../../../model/workspaces/SDKWorkspaceSubscription"
import { DataBridge } from "../SDKDataBridge"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Request engine: Read

export class DataCoreReadRequests {
  private bridge: DataBridge

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(bridge: DataBridge) {
    this.bridge = bridge
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Bootstrap data

  async getRemoteBootstrapData(
    preferredWorkspaceId?: string,
    preferredDesignSystemId?: string,
    preferredVersionId?: string,
    preferredBrandId?: string
  ): Promise<BootstrapDataRemoteModel> {
    let fragment = `bff/app-bootstrap-data`

    const queryParams: Array<string> = []
    if (preferredWorkspaceId) {
      queryParams.push(`preferredWorkspaceId=${preferredWorkspaceId}`)
    }
    if (preferredDesignSystemId) {
      queryParams.push(`preferredDesignSystemId=${preferredDesignSystemId}`)
    }
    if (preferredVersionId) {
      queryParams.push(`preferredVersionId=${preferredVersionId}`)
    }
    if (preferredBrandId) {
      queryParams.push(`preferredBrandId=${preferredBrandId}`)
    }

    if (queryParams.length > 0) {
      fragment += `?${queryParams.join("&")}`
    }

    const endpoint = this.bridge.getBaseEndpoint(fragment)
    const payload = await this.bridge.get(endpoint)

    return payload.result as BootstrapDataRemoteModel
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Multiplayer

  /** Remote: Get information about currently logged in user */
  async getRemoteMultiplayerToken(room: string | undefined): Promise<{
    token: string
  }> {
    const endpoint = this.bridge.getBaseEndpoint("liveblocks/auth")
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, {
      room,
    })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result as {
      token: string
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - User

  /** Remote: Get information about currently logged in user */
  async getRemoteCurrentUser(): Promise<UserRemoteModel> {
    const endpoint = this.bridge.getBaseEndpoint("users/me")
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.user as UserRemoteModel
  }

  /** Remote: Get information about a specific user */
  async getRemoteUser(userId: any): Promise<UserRemoteModel> {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const endpoint = this.bridge.getUserEndpoint(userId, null)
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.user as UserRemoteModel
  }

  /** Remote: Get information about all workspaces user can access */
  async getRemoteMemberships(
    userId: string
  ): Promise<Array<MembershipRemoteModel>> {
    const endpoint = this.bridge.getUserEndpoint(userId, "workspaces")
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.membership as Array<MembershipRemoteModel>
  }

  /** Remote: Get user workspace notification settings */
  async getRemoteUserWorkspaceNotificationSettings(
    userId: string,
    wsId: string
  ): Promise<UserWorkspaceNotificationSettingsRemoteModel> {
    const endpoint = this.bridge.getUserWorkspaceEndpoint(
      userId,
      wsId,
      "notifications"
    )
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result
      .notificationSettings as UserWorkspaceNotificationSettingsRemoteModel
  }

  async getRemoteAcls(): Promise<AclRemoteModel> {
    const endpoint = this.bridge.getAclEndpoint()
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result as AclRemoteModel
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Workspaces

  async getRemoteWorkspaceProductFeatures(
    workspaceId: string
  ): Promise<SubscriptionProductFeaturesRemoteModel> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "product-features"
    )

    const payload = await this.bridge.get(endpoint)
    return payload.result
      .productFeatures as SubscriptionProductFeaturesRemoteModel
  }

  /** Remote: Get information about a specific workspace */
  async getRemoteWorkspaceDetail(
    workspaceId: string
  ): Promise<WorkspaceRemoteModel> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, null)
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result as WorkspaceRemoteModel
  }

  /** Remote: Get integrations of a specific workspace */
  async getRemoteWorkspaceIntegrations(
    workspaceId: string
  ): Promise<WorkspaceIntegrationsRemoteModel> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "integrations"
    )
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result as WorkspaceIntegrationsRemoteModel
  }

  /** Remote: Get redirect url for oauth of given workspace integration */
  async getRemoteWorkspaceIntegrationRedirectUrl({
    workspaceId,
    type,
  }: {
    workspaceId: string
    type: OAuth2IntegrationTypes
  }): Promise<WorkspaceIntegrationOauthRequestRemoteModel> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "oauth-requests"
    )
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, { type })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result as WorkspaceIntegrationOauthRequestRemoteModel
  }

  /** Remote: Get workspace's integration git providers data based on the opration
   * * Request Organizations, Repositories, Branches, etc.
   * * This is generic method for all git providers
   */
  async getRemoteWorkspaceIntegrationGitProvidersData({
    workspaceId,
    integrationId,
    operation,
    params,
  }: {
    workspaceId: string
    integrationId: string
    operation: GitProviderValues
    params?: WorkspaceIntegrationGetGitProvidersInput
  }): Promise<unknown> {
    const endpoint = this.bridge.getWorkspaceIntegrationGitProvidersEndpoint(
      workspaceId,
      integrationId,
      operation
    )
    // Optional params
    const url = new URL(endpoint)

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          url.searchParams.append(key, value)
        }
      })
    }

    // Get the full endpoint with the query string
    const endpointWithParams = url.toString()

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpointWithParams)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result
  }

  /** Remote: Get all users memberships */
  async getRemoteUserMemberships(
    workspaceId: string
  ): Promise<Array<UserMembershipRemoteModel>> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "members")
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.members as Array<UserMembershipRemoteModel>
  }

  /** Remote: Get information about the state of workspace onboarding, what was completed and what not */
  async getRemoteWorkspaceOnboarding(
    workspaceId: string
  ): Promise<WorkspaceOnboardingRemoteModel | null> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "onboarding")
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.onboardingState as WorkspaceOnboardingRemoteModel
  }

  /** Remote: Get information about the workspace IP whitelisting configuration */
  async getRemoteWorkspaceIPWhitelistSettings(
    workspaceId: string
  ): Promise<WorkspaceIPWhitelistSettingsRemoteModel | null> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "ip-whitelist"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result
      .ipWhitelist as WorkspaceIPWhitelistSettingsRemoteModel | null
  }

  async getRemoteWorkspaceNPMRegistrySettings(
    workspaceId: string
  ): Promise<WorkspaceNPMRegistrySettingsRemoteModel | null> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "npm-registry"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result
      .npmRegistrySettings as WorkspaceNPMRegistrySettingsRemoteModel | null
  }

  async getRemoteWorkspaceNPMRegistryAccessToken(
    workspaceId: string
  ): Promise<WorkspaceNPMRegistryAccessTokenRemoteModel | null> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "npm-registry/access-token"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result as WorkspaceNPMRegistryAccessTokenRemoteModel | null
  }

  async getRemoteWorkspaceSSOSettings(
    workspaceId: string
  ): Promise<WorkspaceSSOSettingsRemoteModel | null> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "sso")
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.ssoSettings as WorkspaceSSOSettingsRemoteModel | null
  }

  async getRemoteWorkspaceInvitations(
    workspaceId: string
  ): Promise<Array<WorkspaceInvitationRemoteModel>> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "invitations"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.invitations as Array<WorkspaceInvitationRemoteModel>
  }

  async getRemoteWorkspaceInvoices(
    workspaceId: string
  ): Promise<Array<WorkspaceInvoiceRemoteModel>> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "subscription/invoices"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    return payload.result.invoices as Array<WorkspaceInvoiceRemoteModel>
  }

  async getRemoteWorkspaceSubscription(
    workspaceId: string
  ): Promise<WorkspaceSubscriptionRemoteModel> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "subscription"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    return payload.result.subscription as WorkspaceSubscriptionRemoteModel
  }

  async getRemoteWorkspaceAvailableProducts(
    workspaceId: string
  ): Promise<Array<WorkspaceProductRemoteModel>> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "available-plans"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    return payload.result.availablePlans as Array<WorkspaceProductRemoteModel>
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Design systems

  /** Remote: Get information about one specific design system */
  async getRemoteDesignSystem(
    designSystemId: string
  ): Promise<DTODesignSystem> {
    const endpoint = this.bridge.getDesignSystemEndpoint(designSystemId, null)
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return DTODesignSystem.parse(payload.result.designSystem)
  }

  /** Remote: Get information about all design systems under specific workspace */
  async getRemoteDesignSystems(
    workspaceId: string
  ): Promise<Array<DTODesignSystem>> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "design-systems"
    )

    const payload = await this.bridge.get(endpoint)

    const data = payload.result.designSystems as Array<unknown>
    return data.map((e: unknown) => DTODesignSystem.parse(e))
  }

  /** Remote: Get custom domain */
  async getRemoteCustomDomain(
    designSystemId: string
  ): Promise<CustomDomainRemoteModel> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "custom-domain"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.customDomain as CustomDomainRemoteModel
  }

  /** Remote: Get design system stats */
  async getRemoteDesignSystemStats(
    dsId: string,
    versionId: string,
    brandId?: string
  ): Promise<DTODesignSystemVersionStats> {
    const url = new URL(
      this.bridge.getVersionEndpoint(dsId, versionId, "stats")
    )
    if (brandId) {
      url.searchParams.set("brandId", brandId)
    }

    const payload = await this.bridge.get(url.toString())

    return payload.result as DTODesignSystemVersionStats
  }

  /** Remote: Get a single design source */
  async getRemoteSource(
    designSystemId: string,
    sourceId: string
  ): Promise<SourceRemoteModel> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `sources/${sourceId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.source as SourceRemoteModel
  }

  /** Remote: Get all design sources of a single design system */
  async getRemoteSources(
    designSystemId: string
  ): Promise<Array<SourceRemoteModel>> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "sources"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.sources as Array<SourceRemoteModel>
  }

  /** Remote: Get all figma node structures */
  async getFigmaNodeStructures(
    designSystemId: string,
    versionId: string
  ): Promise<Array<FigmaStructureModel>> {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(
      this.bridge.getVersionEndpoint(
        designSystemId,
        versionId,
        `figma-frames/node-structures`
      )
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.structures as Array<FigmaStructureModel>
  }

  /** Remote: Get figma node detail */
  async getFigmaNodeStructureDetail(
    designSystemId: string,
    versionId: string,
    figmaStructureId: string
  ): Promise<FigmaStructureDetailModel> {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(
      this.bridge.getVersionEndpoint(
        designSystemId,
        versionId,
        `figma-frames/node-structures/${figmaStructureId}`
      )
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.structure as FigmaStructureDetailModel
  }

  /** Remote: Get information about design system's members */
  async getRemoteDesignSystemMemberList(
    designSystemId: string
  ): Promise<DTODesignSystemMemberListResponse> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "members"
    )
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return DTODesignSystemMemberListResponse.parse(payload.result)
  }

  async getRemoteContacts(
    designSystemId: string
  ): Promise<DTODesignSystemContactsResponse> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "contacts"
    )

    const payload = await this.bridge.get(endpoint)
    return payload.result as DTODesignSystemContactsResponse
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - DocumentationLegacy

  /** Remote: Get design system documentation url */
  async getRemoteDocumentationUrl(
    designSystemId: string,
    versionId: string
  ): Promise<string | null> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "documentation/url"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return (payload.result.url as string) ?? null
  }

  /** Remote: Get documentation metadata */
  async getRemoteDocumentationStructure(
    designSystemId: string,
    versionId: string
  ): Promise<DocumentationLegacyModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "documentation"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.documentation as DocumentationLegacyModel
  }

  /** Remote: Get documentation elements diff */
  async getRemoteDocumentationElementsDiff(
    designSystemId: string,
    versionId: string,
    environment: DocumentationEnvironment
  ): Promise<DTODesignElementsDataDiffResponse> {
    const baseEndpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "documentation/elements/diff"
    )

    const params: DTODocumentationPublishTypeQueryParams = {
      environment,
    }

    const baseEndpointUrl = new URL(baseEndpoint)
    baseEndpointUrl.searchParams.append("environment", environment)

    const payload = await this.bridge.get(baseEndpointUrl.toString())

    return payload.result as DTODesignElementsDataDiffResponse
  }

  /** Remote: Get documentation metadata */
  async getRemoteRealDocumentationStructure(
    designSystemId: string,
    versionId: string
  ): Promise<(DocsEntityPageModel | DocsEntityGroupModel)[]> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "documentation/structure"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.items as (
      | DocsEntityPageModel
      | DocsEntityGroupModel
    )[]
  }

  /** Remote: Get id of the room for a specific page */
  async getRemoteDocumentationPageRoomId(
    designSystemId: string,
    versionId: string,
    pageId: string
  ): Promise<string> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `documentation/pages/${pageId}/room`
    )

    const payload = await this.bridge.get(endpoint)

    return payload?.result?.room?.id
  }

  /** Remote: Get id of the room for a specific design system and version. This room contains doc structure */
  async getRemoteDocumentationVersionRoomId(
    designSystemId: string,
    versionId: string
  ): Promise<string> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `room`
    )

    const payload = await this.bridge.get(endpoint)

    return payload?.result?.room?.id
  }

  /** Remote: Get id of the room for a specific workspace. */
  async getRemoteDocumentationWorkspaceRoomId(
    workspaceId: string
  ): Promise<string> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "room")

    const payload = await this.bridge.get(endpoint)

    return payload?.result?.room?.id
  }

  async getRemoteDocumentationPageAnchors(
    designSystemId: string,
    versionId: string,
    pageId: string
  ): Promise<DTOGetDocumentationPageAnchorsResponse> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `documentation/pages/${pageId}/anchors`
    )

    const payload = await this.bridge.get(endpoint)

    return payload?.result
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Exporters

  /** Remote: Get custom blocks for currently installed documentation exporter under specific ds */
  async getRemoteExporterCustomBlocks(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ExporterCustomBlockModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "documentation/custom-blocks"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.customBlocks as Array<ExporterCustomBlockModel>
  }

  /** Remote: Get user configuration of specific installed exporter */
  async getRemoteExporterConfigurationUserSpecifiedValues(
    designSystemId: string,
    exporterId: string
  ): Promise<
    Array<{
      key: string
      value: any
    }>
  > {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `exporter-properties/${exporterId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.items as Array<{
      key: string
      value: any
    }> // TODO: Proper model
  }

  async getBlockDefinitions(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DocsBlockDefinitionModel>> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `versions/${versionId}/documentation/block-definitions`
    )

    const payload = await this.bridge.get(endpoint)

    return DTOGetBlockDefinitionsOutput.parse(payload.result).definitions
  }

  async getUrlPreview(
    designSystemId: string,
    versionId: string,
    request: DTODocumentationLinkPreviewRequest
  ) {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `versions/${versionId}/documentation/link-preview`
    )

    const payload = await this.bridge.post(endpoint, request)

    return payload.result as DTODocumentationLinkPreviewResponse
  }

  /** Remote: Get structural details and metadata about specific installed exporter */
  async getRemoteExporter(
    workspaceId: string,
    exporterId: string
  ): Promise<ExporterModel> {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/exporters/${exporterId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.exporter as ExporterModel
  }

  /** Remote: Get structural details and metadata about all exporters */
  async getRemoteExporters(
    workspaceId: string,
    filter?: {
      installedOnly?: boolean
    }
  ): Promise<{
    exporters: Array<ExporterModel>
    membership: Array<ExporterMembershipModel>
  }> {
    const endpointBase = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/exporters`
    )

    const url = new URL(endpointBase)

    if (filter) {
      if (filter.installedOnly) {
        url.searchParams.append("installedOnly", "true")
      }
    }

    url.searchParams.append("limit", "200")

    // Get the full endpoint with the query string
    const endpoint = url.toString()
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return {
      exporters: payload.result.exporters as Array<ExporterModel>,
      membership: payload.result.membership as Array<ExporterMembershipModel>,
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---e
  // MARK: - Resources

  /** Remote: Get all asset resources */
  async getRemoteAssetResources(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ResourceAssetModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "assets?type=image"
    )

    const payload = await this.bridge.get(endpoint)

    return payload.result.assets as Array<ResourceAssetModel>
  }

  /** Remote: Get all font resources */
  async getRemoteFontResources(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ResourceFontModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "assets?type=font"
    )

    const payload = await this.bridge.get(endpoint)

    return payload.result.assets as Array<ResourceFontModel>
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---e
  // MARK: - Tokens

  /** Remote: Get all tokens from all categories */
  async getRemoteTokens(
    designSystemId: string,
    versionId: string,
    filter?: {
      type?: TokenType
      brandId?: string
    }
  ): Promise<Array<TokenRemoteModel>> {
    // Get the base endpoint without the query string
    const endpointBase = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "tokens"
    )

    // Create a URL object from the base endpoint
    const url = new URL(endpointBase)

    if (filter) {
      // if (filter.type) {
      //   const types = [filter.type] // Prep if we every decide that we want to support multiple types (API supports it)
      //
      //   types.forEach((type) => {
      //     url.searchParams.append("types", type)
      //   })
      // }

      if (filter.brandId) {
        url.searchParams.append("brandId", filter.brandId)
      }
    }

    // Get the full endpoint with the query string
    const endpoint = url.toString()
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.tokens as Array<TokenRemoteModel>
  }

  /** Remote: Get all token groups from all categories */
  async getRemoteTokenGroups(
    designSystemId: string,
    versionId: string,
    filter?: {
      // Turn this off, until implemented to not confuse SDK clients
      // type?: TokenType
      brandId?: string
    }
  ): Promise<Array<TokenGroupRemoteModel>> {
    const endpointBase = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "token-groups"
    )

    // Create a URL object from the base endpoint
    const url = new URL(endpointBase)

    if (filter) {
      // if (filter.type) {
      //   url.searchParams.append("elementType", filter.type)
      //
      //   const types = [filter.type] // Prep if we every decide that we want to support multiple types (API however doesn't support it now)
      //
      //   types.forEach((type) => {
      //     url.searchParams.append("elementType", type)
      //   })
      // }

      if (filter.brandId) {
        url.searchParams.append("brandId", filter.brandId)
      }
    }

    // Get the full endpoint with the query string
    const endpoint = url.toString()
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.groups as Array<TokenGroupRemoteModel>
  }

  /** Remote: Get all token themes from all categories */
  async getRemoteTokenThemes(
    designSystemId: string,
    versionId: string
  ): Promise<Array<TokenThemeRemoteModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "themes"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.themes as Array<TokenThemeRemoteModel>
  }

  /** Remote: Get usage of the token across design system */
  async getRemoteTokenUsage(
    designSystemId: string,
    versionId: string,
    tokenId: string
  ): Promise<{
    tokens: Array<TokenRemoteModel>
    components: Array<DesignComponentRemoteModel>
    documentationPages: Array<DocumentationLegacyPageModel>
  }> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `tokens/${tokenId}/used-by`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result as {
      tokens: Array<TokenRemoteModel>
      components: Array<DesignComponentRemoteModel>
      documentationPages: Array<DocumentationLegacyPageModel>
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Components

  /** Remote: Get all supernova-type components (note: Not design components, those have separate endpoints) */
  async getRemoteComponents(
    designSystemId: string,
    versionId: string,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<ComponentRemoteModel>> {
    const endpointBase = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "design-system-components" // Not a bug, correct endpoint until server serves it right
    )

    const url = new URL(endpointBase)

    if (filter) {
      if (filter.brandId) {
        url.searchParams.append("brandId", filter.brandId)
      }
    }

    const endpoint = url.toString()

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.designSystemComponents as Array<ComponentRemoteModel>
  }

  /** Remote: Get all component groups */
  async getRemoteComponentGroups(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ComponentGroupRemoteModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "design-system-component-groups"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.groups as Array<ComponentGroupRemoteModel>
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Design Components

  /** Remote: Get design components (right now, those are only components coming from Figma) */
  async getRemoteDesignComponents(
    designSystemId: string,
    versionId: string,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<DesignComponentRemoteModel>> {
    const endpointBase = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "components"
    )

    const url = new URL(endpointBase)

    if (filter) {
      if (filter.brandId) {
        url.searchParams.append("brandId", filter.brandId)
      }
    }

    const endpoint = url.toString()

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.components as Array<DesignComponentRemoteModel>
  }

  /** Remote: Get design component groups (right now, those are only components coming from Figma) */
  async getRemoteDesignComponentGroups(
    designSystemId: string,
    versionId: string,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<DesignComponentGroupRemoteModel>> {
    const endpointBase = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "component-groups"
    )

    const url = new URL(endpointBase)

    if (filter) {
      if (filter.brandId) {
        url.searchParams.append("brandId", filter.brandId)
      }
    }

    const endpoint = url.toString()

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.groups as Array<DesignComponentGroupRemoteModel>
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Assets

  /** Remote: Get assets (right now, those are only components coming from Figma, but this will change in the future) */
  async getRemoteAssets(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DesignComponentRemoteModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "components"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.components as Array<DesignComponentRemoteModel>
  }

  /** Remote: Get design component groups (right now, those are only components coming from Figma, but this will change in the future) */
  async getRemoteAssetGroups(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DesignComponentGroupRemoteModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "component-groups"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.groups as Array<DesignComponentGroupRemoteModel>
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Elements

  async getRemoteElementPropertyDefinitions(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ElementPropertyRemoteModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "element-properties/definitions"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.definitions as Array<ElementPropertyRemoteModel>
  }

  /** Remote: Get definitions of all element data views. This fetches element views for all types of elements, ie. tokens, components, assets etc. */
  async getRemoteElementDataViewDefinitions(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ElementDataViewRemoteModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "element-data-views"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.elementDataViews as Array<ElementDataViewRemoteModel>
  }

  /** Remote: Get all property values that were set by the user for each elemen */
  async getRemoteElementPropertyValues(
    designSystemId: string,
    versionId: string
  ) {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "element-properties/values"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.values as Array<ElementPropertyValueRemoteModel>
  }

  async getRemoteFigmaFrames(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DTOFigmaNode>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "elements?types=FigmaNode"
    )

    const payload = await this.bridge.get(endpoint)
    return payload.result.figmaNodes as Array<DTOFigmaNode>
  }

  async getRemoteFigmaFramesV2(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DTOFigmaNodeV2>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "elements?types=FigmaNode&responseVersion=2"
    )

    const payload = await this.bridge.get(endpoint)
    return payload.result.figmaNodes as Array<DTOFigmaNodeV2>
  }

  async getRemoteTokenCollections(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DTOTokenCollection>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "token-collections"
    )

    const payload = await this.bridge.get(endpoint)
    return payload.result.collections as Array<DTOTokenCollection>
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Pipelines

  async getRemotePipelines(
    workspaceId: string,
    designSystemId?: string
  ): Promise<DTOPipeline[]> {
    const endpointBase = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/pipelines?latestJobsLimit=1`
    )

    const url = new URL(endpointBase)
    if (designSystemId) {
      url.searchParams.append("designSystemId", designSystemId)
    }

    const endpoint = url.toString()
    const payload = await this.bridge.get(endpoint)

    const data = payload.result.pipelines as Array<unknown>
    return data.map((pipeline: unknown) => DTOPipeline.parse(pipeline))
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Builds

  async getRemoteBuild(
    workspaceId: string,
    buildId: string
  ): Promise<ExportBuildModel> {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/jobs/${buildId}`
    )

    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.job as ExportBuildModel
  }

  async getRemoteBuilds(
    workspaceId: string,
    versionId: string,
    docsEnvironment?: DocumentationEnvironment,
    limit?: number,
    offset?: number,
    scheduleId?: string,
    exporterId?: string,
    destinations?: Array<
      "s3" | "webhook" | "github" | "documentation" | "azure" | "gitlab"
    >,
    createdByUserId?: string,
    status?: ExportBuildStatus
  ): Promise<Array<ExportBuildModel>> {
    const endpointBase = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/jobs`
    )

    const url = new URL(endpointBase)
    if (limit) {
      url.searchParams.append("limit", limit.toString())
    }
    if (offset) {
      url.searchParams.append("offset", offset.toString())
    }
    if (scheduleId) {
      url.searchParams.append("scheduleId", scheduleId)
    }
    if (exporterId) {
      url.searchParams.append("exporterId", exporterId)
    }
    if (versionId) {
      url.searchParams.append("designSystemVersionId", versionId)
    }
    if (docsEnvironment) {
      url.searchParams.append("docsEnvironment", docsEnvironment)
    }
    if (destinations) {
      destinations.forEach((destination) => {
        url.searchParams.append("destinations[]", destination)
      })
    }
    if (createdByUserId) {
      url.searchParams.append("createdByUserId", createdByUserId)
    }
    if (status) {
      url.searchParams.append("status", status)
    }

    const endpoint = url.toString()
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.jobs as Array<ExportBuildModel>
  }

  async getRemoteBuildLogs(
    workspaceId: string,
    buildId: string
  ): Promise<Array<ExportBuildResultLogModel>> {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/jobs/${buildId}/logs`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.logs as Array<ExportBuildResultLogModel>
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Brands

  async getBrands(
    designSystemId: string,
    versionId: string
  ): Promise<Array<BrandRemoteModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "brands"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.brands as Array<BrandRemoteModel>
  }

  async getBrand(
    designSystemId: string,
    versionId: string,
    brandId: string
  ): Promise<BrandRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `brands/${brandId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.brand as BrandRemoteModel
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Integrations

  async getAuthenticationTokens() {
    const endpoint = this.bridge.getBaseEndpoint(`auth/tokens`)
    return (await this.bridge.get(endpoint)).result
      .tokens as AuthenticationTokens
  }

  async getOAuthURL(service: "figma" | ExporterProvider): Promise<string> {
    const endpoint = this.bridge.getBaseEndpoint(
      `auth/${service}/oauth-request`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return payload.result.url
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Versions

  async getVersion(
    designSystemId: string,
    versionId: string
  ): Promise<DesignSystemVersionRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      null
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.designSystemVersion as DesignSystemVersionRemoteModel
  }

  async getVersions(
    designSystemId: string
  ): Promise<Array<DesignSystemVersionRemoteModel>> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "versions"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result
      .designSystemVersions as Array<DesignSystemVersionRemoteModel>
  }

  async getVersionCreationJob(
    designSystemId: string,
    jobId: string
  ): Promise<DesignSystemVersionCreationJobRemoteModel> {
    const endpoint = this.bridge.getVersionCreationJobEndpoint(
      designSystemId,
      jobId
    )

    const payload = await this.bridge.get(endpoint)

    return payload.result.job as DesignSystemVersionCreationJobRemoteModel
  }

  async getVersionCreationJobs(
    designSystemId: string
  ): Promise<DesignSystemVersionCreationJobRemoteModel[]> {
    const endpoint = this.bridge.getVersionCreationJobsEndpoint(designSystemId)

    const payload = await this.bridge.get(endpoint)

    return payload.result.jobs as DesignSystemVersionCreationJobRemoteModel[]
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Automation

  async getImportJobs(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ImportJobRemoteModel>> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "import-jobs"
    )

    // Create a URL object from the base endpoint
    const url = new URL(endpoint)

    url.searchParams.append("operation", "Import")

    // Get the full endpoint with the query string
    const queriedEndpoint = url.toString()
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(queriedEndpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.jobs as Array<ImportJobRemoteModel>
  }

  async getImportJob(
    designSystemId: string,
    versionId: string,
    jobId: string
  ): Promise<ImportJobRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `import-jobs/${jobId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.job as ImportJobRemoteModel
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Storage

  async getKeyValueStorageData(
    designSystemId: string,
    keys: Array<string>
  ): Promise<Record<string, string>> {
    const keyQuery = keys.map((key) => encodeURIComponent(key)).join(",")
    const endpointFragment = `design-systems/${designSystemId}/metadata?keys=${keyQuery}`
    const endpoint = this.bridge.getBaseEndpoint(endpointFragment)
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.metadata as Record<string, string>
  }

  async getTokenStudioData(
    designSystemId: string,
    versionId: string
  ): Promise<object> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "bff/token-studio"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.get(endpoint)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return payload.result
  }

  async getPageRedirects(designSystemId: string) {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `documentation/redirects`
    )

    const payload = await this.bridge.get(endpoint)

    return payload.result.redirects as PageRedirect[]
  }
}
